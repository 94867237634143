@import "stylesheets/variables.scss";
.Landing {
	background-size: cover;
	background-image: url("/assets/landingBackground.png");
	color: white;
	min-height: calc(100vh - 56px);
	padding: 100px 5% 2%;
}
.Landing.light {
	background-image: url("/assets/landingBackgroundLight.png");
}
.Landing.brand {
	background-image: url("/assets/landingBackgroundBrand.png");
}

.Landing h1 {
	color: #891199;
	font-family: "AvantGardeDemi";
	font-size: 25px;
	font-weight: 500;
	letter-spacing: 10px;
	text-align: center;
	text-transform: uppercase;
}
.Landing.light h1 {
	color: #4400aa;
}
.Landing.brand h1 {
	color: #e60000;
}

.boardsContainer {
	text-align: left;
	margin: 50px 30px;
}
.boardsContainerContent {
	padding: 3%;
	text-align: center;
	/* display: grid;
  grid-template-columns: auto auto auto; */
}

.authLandingCard {
	max-width: 300px;
	border-radius: 11px;
	background: linear-gradient(269.58deg, $active-color 0%, #891199 100%);
	padding: 50px 30px;
	text-align: center;
	margin: 15px auto;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.authLandingCardBrand {
	max-width: 300px;
	border-radius: 11px;
	background: linear-gradient(89.65deg, #51120e 4.34%, #680f0c 22.53%, #e60000 130.37%);
	padding: 50px 30px;
	text-align: center;
	margin: 15px auto;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.authLandingCard-title img {
	width: 100%;
	margin-bottom: 50px;
}
.authLandingCard-footer {
	box-sizing: border-box;
	padding: 20px 10px;
	border-radius: 11px;
	border: 2px solid #fff;
}
.authLandingCard-footer a {
	color: #fff;
	font-family: "AvantGardeDemi";
	font-size: 24px;
	font-weight: 300;
	line-height: 24px;
	text-transform: uppercase;
}

.authLandingCardBrand .authLandingCard-footer:hover {
	border-color: #d04747 !important;
}
.authLandingCard-footer:hover {
	border-color: #a54fc5 !important;
	transition: 0.2s;
	background-color: rgba($color: #fff, $alpha: 0.1);
}

.authLandingCard-footer a:hover {
	color: #fff !important;
	transition: color 0.2s;
}

@media only screen and (max-width: 768px) {
	.Landing h1 {
		letter-spacing: normal;
	}
	.authLandingCard-footer a {
		font-size: 20px;
	}
}
