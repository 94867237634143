@import "stylesheets/variables.scss";

.container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 80px;
  display: flex;
  background-color: #000a1f;
  justify-content: space-between;
  transition: opacity 1s;
  z-index: 10;
  & h4 {
    font-family: "Poppins";
    font-size: 15px;
    font-weight: 300;
    margin: 0;
    color: white;
    margin-top: 8px;
  }
  & .optionContainer {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-content: center;
  }
}
.audienceSelection {
  width: 100%;
  display: flex;

  margin-top: 5px;

  :global {
    .ant-select {
      width: 100%;
    }
  }
}

.con {
  margin-left: 20px;
  border-radius: 20px;
  width: 10px;
  margin-right: 20px;
}

.or {
  margin-left: 2px;
  margin-left: 2px;

  font-size: 18px;
}

.st {
  background: transparent;
}

.on {
  color: black;
}

// .deleteWrapper {
//   display: flex;

//   align-items: center;
//   gap: 10px;
//   background-color: black;
//   padding: 4px;
//   padding: 0px 13px;
//   justify-content: center;
//   border-radius: 4px;
// }
