.EmailVerification{
    padding: 8%;
}
.PasswordVerification{
    width: 500px;
    padding-top: 120px;
    margin: 0 auto;
}
.EmailVerification h1{
    font-style: italic;
}

.verificationBar{
    padding: 10px;
    margin: 20px 10%;
    display: flex;
    margin: 0 !important;
}

.verificationBar h1{
    flex: 4;
    font-size: 1.5em;
}
.verificationBar button{
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: #529AC6;
    border: 0;
    color: #FFFFFF;
    font-size: 1em;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
    flex: 1;
}