@import "stylesheets/variables.scss";
// @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");

/*common*/

.form {
	position: relative;
	z-index: 1;
	/* background: #FFFFFF; */
	max-width: 600px;
	margin: 0 auto 20px;
	text-align: center;
	/* box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24); */
}

.form input,
.form select {
	font-family: "Roboto", sans-serif;
	outline: 0;
	background: #f2f2f2;
	width: 100%;
	border: 0;
	margin: 0;
	padding: 10px;
	box-sizing: border-box;
	font-size: 14px;
}
.loginBtn {
	height: 44px !important;
	width: 100%;
	border-radius: 4px;
	background-color: #891199;
	color: #fff;
	font-size: 18px !important;
	font-weight: 600 !important;
	line-height: 20px;
	text-align: center;
	padding: 0 !important;
	border: 0;
	margin: 34px 0 !important;
}
#mfa-sign-in {
	&:hover,
	&:active,
	&:focus {
		background-color: #891199 !important;
	}
}
/* .form button {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #529ac6;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  margin: 20px auto;
}

.form button:hover,
.form button:active,
.form button:focus {
  background: #529ac6;
} */

.form .message p {
	color: #33e1ff;
	font-family: "AvantGardeDemi";
	font-size: 14px;
	font-weight: 200;
	line-height: 14px;
	text-align: center;
}

.form .message a {
	color: blue !important;
	text-decoration: none;
}

/*login*/
.SignIn {
	display: flex;
	background-color: $body-color;
	min-height: 100vh !important;
	width: 100%;
	position: absolute;
	z-index: 999;
	top: 0;
	left: 0;
	.SignIn-Landing-BG {
		min-height: 100%;
		flex-basis: 50%;
		background: url("/assets/sign_in_landing.svg");
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		@media only screen and (max-width: 880px) {
			display: none;
		}
	}
}
.Login-Page-Container {
	flex-basis: 50%;
	background-color: white;
	@media only screen and (max-width: 880px) {
		flex-basis: 100%;
	}
}
.login-page {
	flex: 2;
	/* height: 91vh; */
	padding: 88px 40px 22px 40px;
	max-width: 500px;
	margin: 0 auto;
}
.login-form label {
	color: #fff;
	// font-family: "AvantGardeDemi";
	font-size: 14px;
	font-weight: 300;
	line-height: 14px;
	text-align: center;
	text-align: left;
	width: 100%;
	margin-top: 20px;
}
.login-page-header {
	text-align: left;
	margin-bottom: 8px;
}
.login-page-header img {
	height: 64px;
	@media only screen and (max-width: 500px) {
		height: 45px;
	}
}
.login-page-header h4 {
	font-family: "Poppins", sans-serif;
	font-size: 36px;
	font-weight: 600;
	color: black;
	margin: 0;
	margin-top: 8px;
}
.login-page-header .login-page-header-subtitle {
	font-size: 20px;
	margin: 0;
	margin-top: 5px;
	@media only screen and (max-width: 500px) {
		font-size: 16px;
	}
}
.SignIn .login-title {
	color: white;
}
.SignIn .login-title h4 {
	color: #fff;
	font-family: "Poppins", sans-serif;
	font-size: 16px;
	font-weight: 300;
	line-height: 20px;
	text-align: center;
}
.form-title h1 {
	font-weight: bold;
	font-size: 25px;
}
.notRegistered {
	font-family: "Poppins", sans-serif;
	font-weight: 300;
	font-size: 16px;
	text-align: left;
	margin-top: 33px;
	margin-bottom: 4px;
	color: black;
	& a {
		color: #a54fc5;
		text-decoration: underline;
		font-weight: 600;
		&:hover {
			color: #a54fc5 !important;
			text-decoration: underline;
		}
	}
}
.forgotPassword {
	text-align: left;
	margin-bottom: 0;
}

.forgotPassword a {
	font-size: 16px;
	font-weight: 300;
	color: #a54fc5 !important;
	text-decoration: underline !important;
}

.or {
	overflow: hidden;
	text-align: center;
	color: #a2a2a3;
	font-size: 16px;
	font-family: "Poppins";
	font-weight: 300;
	margin-bottom: 20px;
}

.or:before,
.or:after {
	background-color: #bdbdbe;
	content: "";
	display: inline-block;
	height: 1px;
	position: relative;
	vertical-align: middle;
	width: 50%;
}

.or:before {
	right: 0.5em;
	margin-left: -50%;
}

.or:after {
	left: 0.5em;
	margin-right: -50%;
}

@media only screen and (max-width: 768px) {
	.login-sidebar {
		display: none;
	}
}

/*signup*/

.SignUp {
	display: flex;
	padding: 4%;
	padding-bottom: 0;
}

.signup-info {
	flex: 2;
}
.SignUp .registration-page {
	flex: 1;
}
#signup-dashboard {
	width: 40vw;
	min-width: 400px;
	margin-bottom: 20px;
	box-shadow: 1px 1px 1px 1px #cccccc;
	border-radius: 10px;
}
.signup-info ul {
	text-align: left;
	list-style: none;
}
.signup-info ul li {
	padding-left: 1.5em;
	margin: 15px;
}
.signup-info ul li::before {
	content: "";
	position: absolute;
	left: 7.5vw;
	width: 24px;
	height: 24px !important;
	background: url("/assets/check_circle_outline-24px.svg");
}
.signup-info h1 {
	font-weight: bold;
	font-size: 35px;
}
.signup-info h3 {
	text-align: left;
	font-size: 20px;
	padding-inline-start: 40px;
}
.terms {
	font-size: 15px;
}
#terms {
	width: auto;
	margin-right: 10px;
}
@media only screen and (max-width: 768px) {
	.SignUp {
		display: block;
	}
}

.login-form input {
	padding: 0 48px 0 16px;
	height: 44px;
	font-size: 16px;
	font-family: "Poppins", sans-serif;
	font-weight: 300;
	border-radius: 6px;
	background-color: white;
	border: 1px solid rgba(162, 162, 163, 0.8);
}

@media only screen and (max-width: 500px) {
	.login-page {
		padding: 65px 15px 22px 15px;
	}
	.login-page-header h4 {
		font-size: 24px;
	}
	.SignIn .login-title h4 {
		font-size: 14px;
	}
	.login-form input {
		height: 40px;
		font-size: 14px;
	}
	.notRegistered {
		font-size: 14px;
		margin-top: 16px;
	}
	.forgotPassword a {
		font-size: 14px;
	}
	.loginBtn {
		margin: 26px 0 !important;
	}
	.or {
		margin-bottom: 16px;
	}
}
