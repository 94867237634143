
@import "stylesheets/variables.scss";

.listing{
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    .customMenu {
        margin: 20px;
        padding: 10px;
        :global {
            .ant-dropdown-menu-title-content {
                display: flex !important;
                column-gap: 5px !important;
                &:hover{
                    background-color: $active-color !important;
                    color: white !important;
                }
                display: flex;
                align-items: center;
                img {
                    display: inline-block;
                    margin-right: 5px;
                    width: 20px;
                }
            }
            .ant-dropdown-menu-item {
                padding: 10px !important;
                display: flex !important;
                column-gap: 10px !important;
                &:hover{
                    background-color: $active-color !important;
                    color: white !important;
                }
                &:first-child {
                    .ant-dropdown-menu-title-content {
                        img {
                            padding: 0 3px;
                        }
                    }
                }
            }
        }
    }
    .listingHeader{
        display: grid;
        grid-template-columns: 350px 1fr;
        padding: 0px 20px;

        .item{
            text-align: left;
            display: flex;
            column-gap: 10px;
        }
        @media only screen and (max-width: 600px) {
            display: none;
        }
    }
    .customMenu {
        margin: 20px;
        padding: 10px;
        :global {
            .ant-dropdown-menu-title-content {
                display: flex !important;
                column-gap: 5px !important;
                &:hover{
                    background-color: $active-color !important;
                    color: white !important;
                }
                display: flex;
                align-items: center;
                img {
                    display: inline-block;
                    margin-right: 5px;
                    width: 20px;
                }
            }
            .ant-dropdown-menu-item {
                padding: 10px !important;
                display: flex !important;
                column-gap: 10px !important;
                &:hover{
                    background-color: $active-color !important;
                    color: white !important;
                }
                &:first-child {
                    .ant-dropdown-menu-title-content {
                        img {
                            padding: 0 3px;
                        }
                    }
                }
            }
        }
    }
    .paginationWrapper {
        padding: 30px 0 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media only screen and (max-width: 380px) {
            justify-content: center;
        }
    
        .innerWrapper {
            display: flex;
            align-items: center;
            margin-right: 20px;
    
            @media only screen and (max-width: 600px) {
                margin-right: 0;
            }
            @media only screen and (max-width: 380px) {
                flex-direction: column;
                justify-content: center;
            }
    
            .paginationBox {
                button {
                    background-color: transparent !important;
                    color: $font-color !important;
                    border: 0px !important;
                    @media only screen and (max-width: 600px) {
                        padding: 4px 15px 4px 0 !important;
                    }
                }
                .pagination {
                    font-size: 16px;
                    display: inline-block;
                    input {
                        display: inline-block;
                        margin-right: 10px;
                        border: 1px solid $font-color;
                        border-radius: 4px;
                        outline: none;
                        width: 70px;
                        height: 30px;
                        padding: 0 8px;
                        background-color: transparent;
                        @media only screen and (max-width: 600px) {
                            width: 60px;
                        }
                    }
                    @media only screen and (max-width: 600px) {
                        padding-right: 15px;
                        font-size: 14px;
                    }
                }
            }
            & .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                background-color: transparent !important;
            }
        }
        .reviewsTotal {
            @media only screen and (max-width: 600px) {
                display: none;
            }
        }
    }
}


.collaps{
    :global {
        .ant-collapse{
            display: flex !important;
            background-color: $second-color !important;
            border: none;
        }
        .ant-collapse-header{
            border-radius: 5px !important;
            padding: 0px !important;
            padding-left: 20px !important;
            border-bottom: none !important;
            display: flex !important;
            align-items: center !important;
            color: $font-color !important;
        }
        .ant-collapse-expand-icon{
            display: flex !important;
            align-items: center !important;
            font-size: 14px !important;
            color: $font-color !important;
        }
        .ant-collapse-item{
            flex: 1 !important;
            border-bottom: $fade-color !important;
        }
        .ant-collapse-content{
            border-top: solid $border-color 1px !important;
        }
        .ant-collapse-content-box{
            background-color: $second-color;
            border-top: none !important;
        }
    }
}
.treeWrapper{
    padding-left: 20px;
    @media only screen and (max-width: 600px) {
        padding-left: 10px;
    }
    :global {
        .ant-tree{
            background-color: $second-color !important;
            color: $font-color !important;
        }
        .ant-tree-list-holder-inner{
            display: grid !important;
        }
        .ant-tree-node-content-wrapper{
            flex: 1;
        }
    }
}
.treeLeaf{
    display: flex;
    .title{
        flex: 1;
        display: flex;
        align-items: center;
        column-gap: 5px;
        color: $font-color;
    }
    .actions{
        flex: 0.2;
        display: flex;
        width: 100px;
        .details{
            flex: 1;
            display: flex;
            justify-content: flex-start;
            color: $font-color;
            .button{
                display: flex;
                column-gap: 5px;
                background-color: $primary-color;
                padding: 5px 10px;
                border-radius: 5px;
                color: white;

            }
           
        }
        .more{
            display: flex;
            justify-content: flex-start;
        }

    }
}

.surverylistItem{
    flex: 1;
    background-color: $second-color;
    padding: 20px;
    padding-left: 0px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    @media only screen and (max-width: 600px) {
        grid-template-columns: 30px 1fr 30px;
    }

    .title{
        flex: 0.35;
        color: $font-color;
        text-align: left;
        font-size: 14px;
        margin: 0px;
    }
    .surveyContent{
        flex: 0.8;
        display: flex;
        justify-content: flex-end;
        color: $font-color;
        .date{
            text-align: left;
            flex: 1;
            @media only screen and (max-width: 600px) {
                display: none;
            }
        }
        .surveyActions{
            @media only screen and (max-width: 600px) {
                display: none;
            }
            flex: 1;
            display: flex;
            .surveyDetails{
                background-color: $primary-color;
                display: flex;
                justify-content: flex-start;
                column-gap: 5px;
                color: white;
                padding: 2px 10px;
                border-radius: 5px;
            }
        }
        .more{
            cursor: pointer;
        }
        .customMenu {
            margin: 20px;
            
           .customMenuItem{
            display: flex;
            column-gap: 10px;
           }
        }
    }
}
.surveryInfoModalPopup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.3);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;

	.dashboardInfoModalContent {
		background-color: $box-color;
		min-width: 600px;
		max-width: 600px;
		padding: 22px 32px;
		border-radius: 6px;
        flex-direction: column;
        row-gap: 20px;
        display: flex;

		@media only screen and (max-width: 850px) {
			max-width: 600px;
			min-width: 600px;
            max-height: 550px;
            overflow: scroll;
			padding: 20px;
		}
		@media only screen and (max-width: 600px) {
			max-width: 300px;
			min-width: 300px;
            max-height: 500px;
            overflow: scroll;
			padding: 20px;
		}
        .steps {
            :global {
                .ant-steps-item-wait .ant-steps-item-icon {
                    background-color: $fade-color;
                }
                .ant-steps-item-process .ant-steps-item-icon {
                    border-color: $button-color;
                }
                .ant-steps-item-finish .ant-steps-item-icon{
                    border-color: $button-color;
                    .anticon svg  {
                        display: flex;
                    }
                }
                .ant-steps-navigation .ant-steps-item::before{
                    background-color: $button-color;
                }
                .ant-steps-navigation .ant-steps-item::after{
                    border: 1px solid $fade-color;
                    border-left: none;
                    border-bottom: none;
                }
                .ant-steps-item-process .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title {
                    color: $font-color;
                    font-size: 14px;
                }
                .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
                    color: $fade-color;
                    font-size: 14px;
                }
                .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
                    color: $fade-color;
                    font-size: 14px;
                }
				.ant-steps-item-icon {
					background-color: $second-color !important;
                    .ant-steps-icon {
                        color: $font-color
                    }
                    &::after {
                        background-color: $button-color !important;
                    }
				}
            }

        }
        .field {
			@media only screen and (max-width: 500px) {
				padding: 0;
			}
			.boxLabel {
				display: block;
				text-align: left;
				font-size: 14px;
                margin-top: 20px;
			}
            .attentionText{
                display: block;
				text-align: left;
				font-size: 14px;
                margin-top: 20px;
                border: 1px solid;
                border-color: $second-color;
                background-color: $second-color;
                color: $font-color;
                border-radius: 3px;
                padding: 20px;
            }
            .summaryList {
                text-align: left;
                font-size: 14px;
                li {
                    margin-top: 20px;
                    tag {
                        border: 1px solid;
                        border-color: $second-color;
                        background-color: $second-color;
                        color: $font-color;
                        border-radius: 3px;
                        padding: 5px;
                    }
                }
            }
			:global {
				.ant-select {
					color: $font-color !important;
				}
				.ant-select-selector {
					background-color: transparent !important;
					border: none !important;
					border-radius: 0 !important;
					padding: 0;
					border-bottom: 1px solid rgba(111, 108, 153, 0.8) !important;
					display: flex;
					justify-content: space-between;
					align-items: center;
					.ant-select-selection-item {
						color: $font-color;
					}
					.ant-select-selection-placeholder {
						text-align: left;
						color: $font-color;
						left: 0;
					}
					.ant-select-selection-search {
						left: 0;
						margin-left: 0;
					}
				}
                .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                    height: 40px;
                    max-width: auto;
                    margin: auto;
                    color: $font-color;
                }
				.ant-select-arrow {
					color: $font-color !important;
					.anticon-search {
						svg {
							font-size: 15px;
						}
					}
				}
				.ant-select-selection-overflow-item {
					.ant-select-selection-item {
						color: $font-color;
						background-color: $second-color !important;
						border: 1px solid $second-color !important;
						.ant-select-selection-item-remove {
							color: $font-color;
							text-align: right;
							vertical-align: 0em;
							&:hover {
								color: $font-color !important;
							}
						}
					}
				}
			}
		}
        .dateSelector {
            flex: 1;
            text-align: left;
            margin-top: 20px;
            font-size: 14px;
            & input {
                background-color: transparent;
                width: 100%;
                border: 1px solid $fade-color;
                border-radius: 10px;
                color: $font-color;
                &:hover {
                    border-color: $button-color;
                }
                &:focus {
                    border-color: $button-color;
                }
                &::placeholder {
                    color: $fade-color;
                }
            }
            & select {
                background-color: transparent;
                width: 100%;
                // max-width: 400px;
                border: 1px solid $fade-color;
                padding: 10px;
                color: $font-color;
                &:hover {
                    border-color: $button-color;
                }
                &:focus {
                    border-color: $button-color;
                }
                & option {
                    background-color: $box-color;
                    &:hover {
                        box-shadow: none !important;
                        background-color: $button-color !important;
                    }
                }
            }
            :global {
                .ant-select {
                    max-width: 400px;
                }
                .ant-select-selector {
                    border: 1px solid $fade-color !important;
                }
                .ant-select-multiple .ant-select-selection-placeholder {
                    color: $fade-color;
                }
                .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
                    height: 40px;
                    max-width: auto;
                    margin: auto;
                    color: $font-color;
                }
                .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
                    border-color: $button-color;
                }
                .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
                    .ant-select-selector {
                    border-color: $button-color;
                }
                .ant-picker {
                    width: 100%;
                }
                .ant-picker-input > input {
                    background-color: transparent;
                    border: 0 !important;
        
                    color: $font-color;
                    &:hover {
                        border-color: $button-color;
                    }
                    &:focus {
                        border-color: $button-color;
                    }
                    &::placeholder {
                        color: $fade-color;
                    }
                }
                .ant-picker,
                .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                    background: transparent;
                    // max-width: 400px;
                    border: 1px solid $fade-color !important;
                    padding: 10px;
                    &:hover {
                        border-color: $button-color;
                    }
                    &::placeholder {
                        color: $fade-color;
                    }
                }
                .ant-radio-wrapper span {
                    color: white;
                }
                .ant-radio-checked .ant-radio-inner,
                .ant-radio-inner::after {
                    background-color: $button-color;
                }
            }
        }
        .Btns {
            justify-content: flex-end ;
            margin-top: 32px;
            display: flex;
            button {
                outline: none;
                border: none;
                cursor: pointer;
                &:first-child {
                    background-color: transparent;
                    text-decoration: underline;
                    margin-right: 32px;
                    &:hover {
                        background-color: transparent !important;
                        color: $font-color !important;
                    }
                }
                &:last-child {
                    background-color: $button-color;
                    padding: 8px 16px;
                    font-weight: 600;
                    border-radius: 3px;
                    color: white;
                }
            }
        }
        .header{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            column-gap:10px ;
            .close{
                cursor: pointer;
            }
            .content{
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                row-gap: 0px;
                .title{
                    margin: 0px;
                    font-size: 18px;
                    font-weight: 500;
                }
                .description{
                    margin: 0px;
                    color: $fade-color;
                }
            }
           
        }
        .popupContent{
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            text-align: left;
            .item{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .title{
                    display: flex;
                    column-gap: 5px;
                }
                .description{
                    margin-left: 30px;
                    color: $fade-color;

                }
                .dashItems{
                    margin-top: 10px;
                    display: flex;
                    column-gap: 5px;
                    .dash{
                        color: white;
                        background-color: $primary-color;
                        padding: 5px 10px;
                        border-radius: 6px;
                    }
                }
                
            }
        }

		
	}
}
