@import "stylesheets/variables.scss";

.mainWindow {
  min-height: calc(100vh - 60px);
  padding: 48px;
  height: 100%;
  color: #fff;
  @media only screen and (max-width: 768px) {
    padding: 30px;
  }
  @media only screen and (max-width: 500px) {
    padding: 15px;
  }
}

.kpi-charts-loader-global {
  max-width: 1030px;
  margin: 0 auto;

  @media only screen and (max-width: 1350px) {
    max-width: 870px;
  }
}

.charts-container-global {
  background-color: rgba(28, 25, 59, 1);
  color: rgba(241, 241, 241, 1);
  padding: 0 20px;
  overflow: hidden;
  @media only screen and (max-width: 500px) {
    position: relative;
  }
}

.mobile-view-next-prev {
  display: none;

  span {
    position: absolute;
    top: 173px;
    transform: translateX(-50%);
  }
  @media only screen and (max-width: 500px) {
    display: block;
  }
  .next-icon {
    right: 0px;
  }
  .prev-icon {
    left: 23px;
  }
}
.charts-carousel-global {
  position: relative;
  height: 400px;
  @media only screen and (max-width: 1350px) {
    height: 360px;
  }
  overflow-x: scroll;
  overflow-y: hidden;
  @media only screen and (max-width: 500px) {
    max-width: 200px;
    margin: 0 auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.charts-carousel-global::-webkit-scrollbar {
  width: 22px;
  height: 20px;
}
.presentation-mode-container .charts-carousel-global {
  overflow: hidden;
}
.presentation-mode-review .charts-carousel-global {
  overflow: hidden;
}
.charts-carousel-global::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: rgba(28, 25, 59, 1);
}

.charts-carousel-global::-webkit-scrollbar-thumb {
  background-color: rgba(217, 217, 217, 0.4);
  border-top: solid 7px rgba(28, 25, 59, 1);
  border-bottom: solid 7px rgba(28, 25, 59, 1);
  border-radius: 8px;
}
.charts-wrapper {
  display: flex;
  position: absolute;
  top: 50px;
  left: 0;
  padding: 0px 0 20px 0;
  height: 100%;
  transition: left 0.5s linear;
}

.next-prev-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 968px) {
    visibility: hidden;
  }
}
.next-prev-btn span {
  display: inline-block;
  padding: 10px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.next-prev-btn svg {
  font-size: 18px;
}
.next-prev-btn .action-btn:hover {
  background-color: $border-color;
}
.next-prev-btn .page-no-info {
  font-size: 14px;
  padding: 0;
  margin: 0 15px;
  font-weight: 600;
  color: rgba(241, 241, 241, 1);
}

.chart-item-global {
  position: relative;
  flex-shrink: 0;
  width: 240px !important;
  height: 340px !important;
  background-color: rgba(16, 13, 49, 1);
  border-radius: 5px;
  margin-right: 10px;
  padding: 10px 15px;

  @media only screen and (max-width: 1350px) {
    width: 200px !important;
    height: 300px !important;
  }
}

.chart-item-global:hover > .drag-item-icon {
  display: block;
  animation: drag_icon_animate 0.4s ease 1 forwards;
  @media only screen and (max-width: 968px) {
    display: none;
  }
}
.chart-item-global:active > .drag-item-icon {
  display: block;
  top: -12px;
  @media only screen and (max-width: 968px) {
    display: none;
  }
}
@keyframes drag_icon_animate {
  0% {
    top: -20px;
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  100% {
    top: -12px;
    opacity: 1;
  }
}

.chart-item-global .drag-item-icon {
  display: none;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 3;
}
.drag-item-icon svg {
  width: 40px !important;
  font-size: 24px;
  color: #111111 !important;
}
.chart-item-global .gauge-chart {
  margin-top: 20px;
  width: 100%;
  position: relative;
}
.gauge-chart .points {
  position: absolute;
  top: 70%;
  @media only screen and (max-width: 1350px) {
    top: 65%;
  }
  font-size: 13px;
}
.points.start {
  right: 90%;
}
.points.end {
  left: 90%;
}
.chart-item-global .gauge-chart .text-group {
  transform: translate(36%, 55%) !important;
  font-size: 14px !important;
}
.gauge-chart {
  .gauge-percent-indicator-wrapper {
    position: absolute;
    top: 38%;
    display: flex;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    .custom-percentage-change {
      font-size: 20px;
    }
    .no-change-custom-percentage {
      font-size: 14px;
    }
  }
}
.chart-item-global .gauge-chart .percent-text {
  margin-top: 20px !important;
  font-size: 18px !important;
  font-family: "Poppins" !important;
  @media only screen and (max-width: 1350px) {
    font-size: 16px !important;
  }
}
.gauge-chart .data-month-label {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  margin-top: 20px;
  @media only screen and (max-width: 1350px) {
    font-size: 18px !important;
    margin-top: 20px;
  }
}

.chart-item-global .line-chart {
  margin: 0px 10px 30px 10px;
  width: 190px !important;
  position: relative;
  @media only screen and (max-width: 1350px) {
    width: 140px !important;
  }
}

.topic-name {
  max-width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  text-transform: uppercase;
  text-decoration: underline;
  text-align: left;
  margin-bottom: 5px;
  color: #d9d9d9;
  word-break: break-all;
  height: 40px !important;
  cursor: pointer;
  font-weight: 600;
  @media only screen and (max-width: 1350px) {
    font-size: 12px;
  }
}

.react-grid-item > .react-resizable-handle {
  visibility: hidden;
}

.present-mode-block {
  display: flex;
  align-items: center;
  position: relative;

  @media only screen and (max-width: $max-size-2) {
    margin-top: 15px;
    width: 100%;
    justify-content: flex-start;
    margin-left: 10px;
  }
}
.present-mode-block button {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
}
.present-mode-block .present-btn {
  background-color: rgba(137, 17, 153, 1);
  color: #fff;
  border-radius: 6px;
  padding: 7px 15px;
  font-size: 16px;
  font-weight: 600;
  margin-right: 15px;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
  &:hover {
    background-color: rgba(137, 17, 153, 1) !important;
    color: #fff !important;
  }
}
.present-btn span {
  display: inline-block;
  margin-left: 8px;
}
.download-btn svg {
  font-size: 18px;
}

.progress-bar-container {
  margin: 10px 0;
  display: none;
}

.progress-bar-container .progress-bar {
  height: 8px;
  width: 200px;
  background-color: rgba(217, 217, 217, 0.4);
  border-radius: 8px;
  transition: transform 0.3s cubic-bezier(0, -0.08, 0, 0.74);
}

.charts-carousel-global .empty-chart-item {
  display: flex;
  justify-content: center !important;
  align-items: center;
}
.charts-carousel-global .empty-chart-item::after {
  position: absolute;
  content: "";
  width: 200px;
  height: 300px;
  border: 2px dotted rgba(217, 217, 217, 0.8);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media only screen and (max-width: 1350px) {
    width: 160px;
    height: 250px;
  }
}
.add-chart-item-btn {
  border: 2px solid $button-color !important;
  color: $button-color !important;
  background-color: transparent !important;
  z-index: 2;
}
.charts-container-global .add-chart-item-btn:hover {
  border: 2px solid $button-color !important;
  background-color: $button-color !important;
}
.popup-content-box1 {
  position: absolute;
  top: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.9) !important;
  z-index: 3;
  padding: 16px;
  width: 300px !important;
  background-color: $box-color !important;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
}
.popup-content-box1 .heading {
  margin-bottom: 10px;
}
.popup-content-box1 .close-popup-btn {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 16px;
  bottom: 80%;
  font-weight: 600;
  cursor: pointer;
  padding: 3px;
}
.close-popup-btn svg {
  width: 14px;
  fill: rgba(217, 217, 217, 1) !important;
}

.popup-content-box1 .ant-select {
  width: 100% !important;
  border-radius: 0px;
  color: rgba(255, 255, 255, 0.9) !important;
}
.popup-content-box1
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid transparent !important;
  padding: 0 !important;
  width: 100% !important;
  border-radius: 0px !important;
  background-color: transparent !important;
  border-bottom: 1px solid $fade-color !important;
}
.popup-content-box1 .ant-select-selector {
  border: none;
  width: 100% !important;
  border-radius: 0px !important;
  border-bottom: 1px solid $fade-color !important;
}
.popup-content-box1 .ant-select-selection-search {
  width: 100% !important;
  left: 0 !important;
}
.popup-content-box1
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: none;
}
.popup-content-box1 {
  .ant-select-selector {
    .ant-select-selection-placeholder {
      left: 0 !important;
      color: $font-color !important;
    }
  }
  .ant-select-selection-overflow-item {
    .ant-select-selection-item {
      color: $font-color;
      background-color: $second-color !important;
      border: 1px solid $second-color !important;
      .ant-select-selection-item-remove {
        color: $font-color;
        &:hover {
          color: $font-color !important;
        }
      }
    }
  }
}
.popup-content-box1 {
  .kpi-selector {
    .ant-select-selection-item {
      color: $font-color;
    }
  }
}

.popup-add-block {
  margin-top: 25px;
}
.popup-add-block button {
  width: 100%;
  background-color: $button-color !important;
  color: white !important;
  border: none !important;
  outline: none !important;
  border-radius: 6px;
}
.popup-content-box1 .popup-add-block button:hover {
  background-color: $button-color !important;
  color: white !important;
}

.metric-option:not(.ant-select-item-option-disabled) {
  background-color: $box-color !important;
}
.metric-option:not(.ant-select-item-option-disabled)
  .ant-select-item-option-content {
  color: $font-color !important;
}
.metric-option.ant-select-item-option-selected .ant-select-item-option-content {
  color: white !important;
}
.metric-option.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: $button-color !important;
  .ant-select-item-option-content {
    color: white !important;
  }
}
.metric-option .ant-select-item-option-state {
  color: transparent !important;
}
.custom-option-heading {
  color: rgba(148, 47, 161, 1) !important;
}

.topic-name-more {
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: space-between;
}
.chart-more-option {
  display: block;
  width: max-content;
  margin-left: 5px;
  cursor: pointer;
  padding: 3px;
  position: relative;
}
.chart-more-option .more-icon {
  font-size: 16px !important;
  cursor: pointer;
  color: #d9d9d9 !important;
}
.more-option-list {
  position: absolute;
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  top: 20px;
  right: 0;
  background-color: $box-color;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
  padding: 5px 8px 5px 2px;
  border-radius: 4px;
  z-index: 5;
}

.more-option-list span {
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
  display: inline-block;
  padding: 2px 10px;
  margin-top: 5px;
  color: $font-color;
  display: flex;
  align-items: center;
  img {
    width: 16px;
    display: inline-block;
    margin-right: 5px;
  }
}

.chart-item-global .custom-topic-chart {
  height: 96px;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 1350px) {
    height: 76px;
  }
  .data-month-label {
    font-size: 20px;
  }
}
.custom-topic-chart .percent-indicator-wrapper {
  display: flex;
  align-items: center;
  img {
    display: inline-block;
    margin-left: 4px;
  }
}
.chart-item-global .custom-points-change {
  font-size: 24px;
  font-weight: 600;
  @media only screen and (max-width: 1350px) {
    font-size: 18px;
  }
}
.chart-item-global .custom-percentage-change {
  font-size: 24px;
  @media only screen and (max-width: 1350px) {
    font-size: 24px;
  }
}

// EXECUTIVE DASHBOARD BELOW BLOCK
.overall-details-container-global {
  display: flex;
  padding-bottom: 20px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.overall-details-container-global .box {
  height: 198px;
  border-radius: 5px;
  padding: 7px 12px;
}
.below-topic-name-more {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.overall-details-container-global .negative-topics-block {
  width: 365px;
  background-color: rgba(16, 13, 49, 1);
  @media only screen and (max-width: 1350px) {
    width: 305px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}
.overall-details-container-global .platform-distribution-block {
  width: 365px;
  margin: 0 10px;
  background-color: rgba(16, 13, 49, 1);
  @media only screen and (max-width: 1350px) {
    width: 305px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 10px 0;
  }
}
.overall-details-container-global .data-collected-block {
  width: 240px;
  background-color: rgba(16, 13, 49, 1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  @media only screen and (max-width: 1350px) {
    width: 200px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    min-height: 198px;
    height: auto;
  }
}
.below-empty-block1 {
  position: relative;
  display: flex;
  justify-content: center !important;
  align-items: center;
}
.below-empty-block1::after {
  position: absolute;
  content: "";
  border: 2px dotted rgba(217, 217, 217, 0.8);
  width: 300px;
  height: 141px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 1350px) {
    width: 250px;
    height: 141px;
  }
}
.overall-details-container-global .below-empty-block2 {
  position: relative;
  display: flex;
  justify-content: center !important;
  align-items: center;
}
.below-empty-block2::after {
  position: absolute;
  content: "";
  width: 201px !important;
  height: 141px !important;
  border: 2px dotted rgba(217, 217, 217, 0.8);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 1350px) {
    width: 120px !important;
    height: 141px !important;
  }
  @media only screen and (max-width: 768px) {
    width: 250px !important;
    height: 141px !important;
  }
}

.box .overall-block-heading {
  color: #d9d9d9;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  text-align: left;
}
.overall-details-container-global .box .top-listed-items {
  list-style: none;
  margin-bottom: 0;
  padding-left: 20px !important;
  @media only screen and (max-width: 768px) {
    padding-left: 0 !important;
  }
}
.box .top-listed-items.custom {
  margin-top: 30px;
}
.top-listed-items li {
  text-align: left;
  padding: 3px 0;
  border-bottom: 1px solid rgba(53, 50, 74, 0.5);
  font-size: 14px;
  color: #f1f1f1;
  display: flex;
  justify-content: space-between;
}
.top-listed-items li .points {
  color: rgba(194, 76, 73, 1);
}
.top-listed-items li:last-child {
  border-bottom: none;
}
.top-listed-items .top-listed-title {
  text-align: right;
  display: block;
  font-size: 14px;
}

.platform-distribution-block .platform-list-item {
  margin-top: 8px;
  font-size: 13px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.platform-list-item .platform-name {
  width: 120px;
  text-align: right;
}
.platform-list-item .percent-indicator {
  width: 100px;
  height: 13px;
  background-color: #942fa1;
  display: inline-block;
  margin: 0 10px 0 5px;
}

.data-collected-block .data-collected-points {
  font-size: 34px;
  color: #942fa1;
  font-weight: 600;
  @media only screen and (max-width: 768px) {
    font-size: 28px;
  }
  @media only screen and (max-width: 500px) {
    font-size: 24px;
  }
}
.data-collected-block .data-collected-date-info {
  font-size: 13px;
}
.data-collected-block {
  .platform-data-collected-wrapper {
    @media only screen and (max-width: 769px) {
      canvas {
        height: 150px !important;
        width: max-content !important;
      }
    }
    @media only screen and (min-width: 1300px) {
      canvas {
        height: 120px !important;
        width: 180px !important;
      }
    }
    @media only screen and (min-width: 770px) and (max-width: 1299px) {
      canvas {
        height: 120px !important;
        width: 160px !important;
      }
    }
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .total-useful-data-info {
      display: flex;
      align-items: center;

      :first-child {
        font-size: 22px;
        font-weight: 600;
        color: #942fa1;
        @media only screen and (max-width: 1350px) {
          font-size: 20px;
        }
      }
      :last-child {
        margin-left: 16px;
        display: inline-block;
        @media only screen and (max-width: 1350px) {
          margin-left: 5px;
        }
      }

      @media only screen and (max-width: 768px) {
        margin-top: 15px;
      }
    }
  }
}

// PRESENTATION MODE
.kpi-charts-loader-global.presentation-mode-container {
  margin: 47px auto 0 auto;
  max-width: 1217px !important;
}
.kpi-charts-loader-global.presentation-mode-container .charts-container-global {
  background-color: #0e0c1d;
  padding: 0 24px;
}
.kpi-charts-loader-global.presentation-mode-review {
  margin: 47px auto 0 auto;
  max-width: 1800px !important;
}
.kpi-charts-loader-global.presentation-mode-review .charts-container-global {
  background-color: #0e0c1d;
  padding: 0 24px;
  overflow: scroll;
}
.presentation-mode-title {
  color: rgba(255, 255, 255, 0.9);
  padding: 10px 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(38, 34, 80, 1);
}
.presentation-mode-title img.logo {
  width: 90px;
  display: inline-block;
}
.presentation-mode-title span {
  font-size: 18px;
}
.presentation-mode-title img {
  cursor: pointer;
}
.presentation-mode-review .chart-item-global {
  max-width: 340px !important;
  width: 340px !important;
  height: 500px !important;
  margin-right: 16px;
  background-color: #1a192b !important;
}
.presentation-mode-container .chart-item-global {
  width: 280px !important;
  height: 394px !important;
  margin-right: 16px;
  background-color: #1a192b !important;
}
.presentation-mode-review {
  .charts-carousel-global {
    .charts-wrapper {
      padding: 0 0 34px 0;
      top: 50px;
    }
  }
  .chart-item-global {
    .topic-name {
      font-size: 16px;
      height: 50px !important;
    }
    .custom-percentage-change {
      font-size: 30px;
    }
    .data-month-label {
      font-size: 24px;
    }
    .gauge-chart .percent-text {
      font-size: 24px !important;
    }

    .gauge-chart .data-month-label {
      font-size: 20px;
      margin-top: 20px;
    }
    .gauge-chart {
      margin-top: 25px;
      .gauge-percent-indicator-wrapper {
        .custom-percentage-change {
          font-size: 24px;
        }
      }
    }
    .custom-topic-chart {
      padding: 1rem 0;
      margin-bottom: 10px;
    }
  }
  .add-kpi-group-name-container {
    font-size: 20px;
    top: 10px;
  }
}
.presentation-mode-container {
  .charts-carousel-global {
    .charts-wrapper {
      padding: 0 0 34px 0;
      top: 50px;
    }
  }
  .chart-item-global {
    .topic-name {
      font-size: 16px;
      height: 50px !important;
    }
    .custom-percentage-change {
      font-size: 30px;
    }
    .data-month-label {
      font-size: 24px;
    }
    .gauge-chart .percent-text {
      font-size: 24px !important;
    }

    .gauge-chart .data-month-label {
      font-size: 20px;
      margin-top: 20px;
    }
    .gauge-chart {
      margin-top: 25px;
      .gauge-percent-indicator-wrapper {
        .custom-percentage-change {
          font-size: 24px;
        }
      }
    }
    .custom-topic-chart {
      padding: 1rem 0;
      margin-bottom: 10px;
    }
  }
  .add-kpi-group-name-container {
    font-size: 20px;
    top: 10px;
  }
}
.presentation-mode-container .chart-item-global .line-chart {
  width: 180px !important;
  margin: 50px auto 0 auto;
}
.presentation-mode-container .chart-item-global:hover > .drag-item-icon {
  display: none;
}
.presentation-mode-container .chart-item-global:active > .drag-item-icon {
  display: none;
}
.presentation-mode-container .chart-item-global .custom-topic-chart {
  height: 80px;
}
.presentation-mode-container .charts-carousel-global {
  height: 460px !important;
}
.presentation-mode-container .progress-bar-container {
  margin: 0;
  padding: 5px 0;
}
.presentation-mode-container .progress-bar {
  display: none !important;
}
.presentation-mode-container .overall-details-container-global .box {
  height: 230px;
  background-color: #1a192b;
}
.presentation-mode-container
  .overall-details-container-global
  .negative-topics-block {
  width: 428px;
}
.presentation-mode-container
  .overall-details-container-global
  .platform-distribution-block {
  margin: 0 16px;
  width: 428px;
}
.presentation-mode-container
  .overall-details-container-global
  .data-collected-block {
  width: 280px;
}
.presentation-mode-container:not(:root):fullscreen {
  width: 100vw !important;
  height: 100vh !important;
}

.presentation-mode-review .chart-item-global .line-chart {
  width: 180px !important;
  margin: 50px auto 0 auto;
}
.presentation-mode-review .chart-item-global:hover > .drag-item-icon {
  display: none;
}
.presentation-mode-review .chart-item-global:active > .drag-item-icon {
  display: none;
}
.presentation-mode-review .chart-item-global .custom-topic-chart {
  height: 80px;
}
.presentation-mode-review .charts-carousel-global {
  height: 560px !important;
}
.presentation-mode-review .progress-bar-container {
  margin: 0;
  padding: 5px 0;
}
.presentation-mode-review .progress-bar {
  display: none !important;
}
.presentation-mode-review .overall-details-container-global .box {
  height: 230px;
  background-color: #1a192b;
}
.presentation-mode-review
  .overall-details-container-global
  .negative-topics-block {
  width: 698px;
}
.presentation-mode-review
  .overall-details-container-global
  .platform-distribution-block {
  margin: 0 16px;
  width: 700px;
}
.presentation-mode-review
  .overall-details-container-global
  .data-collected-block {
  width: 318px;
}
.presentation-mode-review:not(:root):fullscreen {
  width: 100vw !important;
  height: 100vh !important;
}

// OVERRIDE FOR THEME
.mainWindow.light-mode {
  background-color: #fff;
  .present-mode-block {
    .present-btn {
      background-color: #4400aa;
      color: white !important;
      &:hover {
        background-color: #4400aa !important;
        color: white !important;
      }
    }
  }
}
.mainWindow.brand-mode {
  background-color: #fff;
  .present-mode-block {
    .present-btn {
      background-color: #e60000;
      color: white !important;
      &:hover {
        background-color: #e60000 !important;
        color: white !important;
      }
    }
  }
}
.light-theme-executive {
  background-color: #fff;

  .presentation-mode-title {
    background-color: #4400aa;
    span {
      font-weight: 600;
    }
  }
  .next-prev-btn {
    span {
      color: #000000;
    }
    .svg-inline--fa {
      color: #000000 !important;
    }
    .action-btn:hover {
      background-color: $fade-color;
    }
  }
  .charts-container-global {
    background-color: #e8e0f4 !important;
    .chart-item-global {
      background-color: #fff;
      .topic-name {
        color: #000000;
      }
      .chart-more-option {
        .more-icon {
          color: #000000 !important;
        }
      }
      .custom-topic-chart {
        color: #000000;
      }
      .increase-decrease-point {
        color: #000000;
      }
      .gauge-chart {
        .points {
          color: #000000;
        }
        .data-month-label {
          color: #000000;
          margin-top: 20px;
        }
      }
    }
    .overall-details-container-global {
      .box {
        background-color: #fff;
        .overall-block-heading {
          color: #000000;
        }
        .top-listed-items {
          li {
            color: #000000;
            border-bottom: 2px solid rgba(175, 175, 175, 0.5);
          }
        }
        .more-icon {
          color: #000000 !important;
        }
        .data-collected-date-info {
          color: #000000;
        }
        .platform-list-item {
          span {
            color: #000000;
          }
          .percent-indicator {
            background-color: #4400aa;
          }
        }
        .data-collected-points {
          color: #4400aa;
        }
        .total-useful-data-info {
          :first-child {
            color: #4400aa;
          }
          :last-child {
            color: #000000;
          }
        }
      }
    }
  }
}
.brand-theme-executive {
  background-color: #fff;
  .presentation-mode-title {
    background-color: #222222;
    padding: 8px 36px;
    span {
      font-weight: 600;
      img {
        width: 60px;
        height: 60px;
        object-fit: center;
        border-radius: 50%;
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
  .next-prev-btn {
    span {
      color: #000000;
    }
    .svg-inline--fa {
      color: #000000 !important;
    }
    .action-btn:hover {
      background-color: $fade-color;
    }
  }
  .charts-container-global {
    background-color: #222222 !important;
    .chart-item-global {
      background-color: #fff;
      .topic-name {
        color: #4a4d4e;
      }
      .chart-more-option {
        .more-icon {
          color: #4a4d4e !important;
        }
      }
      .custom-topic-chart {
        color: #4a4d4e;
      }
      .increase-decrease-point {
        color: #4a4d4e;
      }
      .gauge-chart {
        .points {
          color: #4a4d4e;
        }
        .data-month-label {
          color: #4a4d4e;
          margin-top: 20px;
        }
      }
    }
    .overall-details-container-global {
      .box {
        background-color: #fff;
        .overall-block-heading {
          color: #4a4d4e;
        }
        .top-listed-items {
          li {
            color: #000000;
            border-bottom: 2px solid rgba(175, 175, 175, 0.5);
          }
        }
        .more-icon {
          color: #000000 !important;
        }
        .data-collected-date-info {
          color: #000000;
        }
        .platform-list-item {
          span {
            color: #000000;
          }
          .percent-indicator {
            background-color: #e60000;
          }
        }
        .data-collected-points {
          color: #e60000;
        }
        .total-useful-data-info {
          :first-child {
            color: #000000;
          }
          :last-child {
            color: #000000;
          }
        }
      }
    }
  }
}
.light-theme-executive .charts-carousel-global::-webkit-scrollbar-track {
  background-color: #e8e0f4;
  border-radius: 0;
}
.brand-theme-executive .charts-carousel-global::-webkit-scrollbar-track {
  background-color: #222222;
  border-radius: 0;
}
.light-theme-executive .charts-carousel-global::-webkit-scrollbar-thumb {
  border-top: solid 7px #e8e0f4;
  border-bottom: solid 7px #e8e0f4;
  background-color: #22222242 !important;
}
.brand-theme-executive .charts-carousel-global::-webkit-scrollbar-thumb {
  border-top: solid 7px #222222;
  border-bottom: solid 7px #222222;
}

.light-theme-executive .presentation-mode-container .chart-item-global {
  background-color: #fff !important;
}
.brand-theme-executive .presentation-mode-container .chart-item-global {
  background-color: #fff !important;
}
.light-theme-executive .presentation-mode-review .chart-item-global {
  background-color: #fff !important;
}
.brand-theme-executive .presentation-mode-review .chart-item-global {
  background-color: #fff !important;
}

.mainWindow {
  .team-kpi-selector {
    .ant-select-selector {
      background-color: $border-color !important;
      border: 1px solid $border-color !important;
      .ant-select-selection-item {
        color: #fff !important;
      }
    }
    .ant-select-arrow {
      color: #fff !important;
    }
  }
}

// RENAME KPI POPUP
.rename-kpi-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
  .platform-content-center-wrapper {
    padding-left: 220px;
    @media only screen and (max-width: 1080px) {
      padding-left: 0;
    }
    .modal-content {
      background-color: $box-color;
      width: 395px;
      padding: 16px;
      border-radius: 6px;
      color: $font-color;
      @media only screen and (max-width: 500px) {
        width: 280px;
      }
      .modal-content-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          margin: 0;
          text-align: left;
        }
        img {
          cursor: pointer;
        }
      }
      input {
        background-color: transparent;
        color: $font-color;
        border: 1px solid rgba(111, 108, 153, 0.8);
        outline: none;
        padding: 0 16px;
        border-radius: 3px;
        height: 32px;
        display: block;
        width: 100%;
        margin: 24px 0;
      }
      .rename-btn-block {
        display: flex;
        justify-content: flex-end;
        button {
          border: none;
          outline: none;
          background-color: $button-color;
          color: white;
          border-radius: 3px;
          padding: 8px 16px;
          font-weight: 600;
          font-size: 14px;
        }
      }
    }
  }
}

.add-kpi-group-name-container {
  width: 100%;
  position: sticky;
  font-size: 16px;
  top: 5px;
  left: 0;
  font-weight: 600;
  z-index: 4;
  @media only screen and (max-width: 500px) {
    display: none;
  }
  @media only screen and (max-width: 750px) and (min-width: 501px) {
    max-width: 200px;
  }
  .add {
    text-decoration: underline;
    i {
      display: inline-block;
      margin-right: 5px;
    }
  }
  div {
    cursor: pointer;
    margin: 0 auto;
    width: 100%;
    max-width: 400px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media only screen and (max-width: 500px) {
      display: none;
    }
    @media only screen and (max-width: 750px) and (min-width: 501px) {
      max-width: 200px;
    }
    img {
      display: inline-block;
      width: 18px;
      margin-left: 3px;
      margin-bottom: 3px;
    }
  }
}
