
@import "stylesheets/variables.scss";
.backdrop {
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .illustration {
        .illus {
            max-width: 250px;
            @media only screen and (max-width: $max-size-2) {
                max-width: 200px;
            }
        }
    }

    .inputs {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        .btn {
            width: 100%;
            height: 44px !important;
            border-radius: 6px !important;
            background-color: #891199 !important;
            color: #fff !important;
            font-family: "Poppins" !important;
            font-size: 18px !important;
            font-weight: 600 !important;
            line-height: 20px !important;
            text-align: center !important;
            border: 0 !important;
        }
        .passwordBlock {
            position: relative;
            .title{
                text-align: left;
            }

            input {
                margin: 0 !important;
            }
            .showHidePassword {
                position: absolute;
                display: inline-block;
                padding: 3px;
                right: 13px;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
            }
            .showHide {
                position: absolute;
                display: inline-block;
                padding: 3px;
                right: 13px;
                top: 65%;
                transform: translateY(-50%);
                cursor: pointer;
            }
            .description{
                margin-top: 5px;
                text-align: left;
                font-size: 12px;
                color: $fade-color;
            }
            .weak{
                color: $attention-color;
            }
            .strong{
                color:#4BB543;
            }
        }
        .error{
            border: 1px solid $attention-color;
        }
        .inputForm {
            box-sizing: border-box;
            height: 44px;
            width: 400px;
            border-radius: 6px;
            border: 1px solid rgba(162, 162, 163, 0.8);
            padding: 0 11px !important;
            @media only screen and (max-width: $max-size-2) {
                width: 100%;

            }
            &.error{
                border: 1px solid $attention-color;
            }
            input {
                height: 100% !important;
            }

            &:focus {
                border: 1px solid rgba(162, 162, 163, 0.8) !important;
                outline: none !important;
                box-shadow: none !important;
            }

            &:global {
                &.ant-input-affix-wrapper {
                    &.ant-input-password {
                        &:hover {
                            border-color: rgba(162, 162, 163, 0.8) !important;
                        }

                        &.ant-input-affix-wrapper-focused {
                            box-shadow: none !important;
                        }

                        .ant-form-item-feedback-icon {
                            height: 26px;
                        }

                        .anticon-eye {
                            color: #891199 !important;
                        }

                        .anticon-eye-invisible {
                            color: #891199 !important;
                        }
                    }
                }
            }
        }

    }
    .content{
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        .title{
            font-size:1.8rem;
            font-weight: 600;
            padding-bottom: 10px;
        }
        .infoText{
            .description{
                font-size: 1rem;
            }
            .link{
                cursor: pointer;
                color: $active-color;
                text-decoration: underline;
            }
        }
    }

}