@import "stylesheets/variables.scss";
.summaryItemWrapper{
    background-color: $second-color;
    margin: 10px;
	
    .header{
        display: flex;
        flex-direction: row;
        justify-content: space-between !important;
        align-items: flex-start !important;
        padding: 20px 20px 10px 20px;
        border-bottom: 1px solid rgba(111, 108, 153, 0.8);
        margin-bottom: 0px !important;

        .title{
            font-weight: 500;
            font-style: normal;
            font-weight: 500;
            text-align: left;
            font-size: 14px;
        }
		.button{
			color: $font-color;
			cursor: pointer;
		}
    }
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: max-content;
    column-gap: 10px;
    padding: 0 16px;
    border-top: 1px solid rgba(111, 108, 153, 0.8);
    min-height: 48px;
	max-height: 140px;

    :global {
        .ant-checkbox {
            .ant-checkbox-inner {
                background-color: transparent !important;

                border-radius: 2px !important;
                border: 1px solid rgba(162, 162, 163, 0.8) !important;
            }
        }
        .ant-checkbox-checked {
            .ant-checkbox-inner {
                background-color: $active-color !important;

                border-radius: 2px !important;
            }
        }
        .ant-input {
            border-color: rgba(162, 162, 163, 0.8) !important;
            &:focus,
            &:hover {
                border-color: rgba(162, 162, 163, 0.8) !important;
                box-shadow: none !important;
            }
        }
    }
    

    .topicName {
        flex: 3;
        text-align: left;
        word-break: break-all;
    }

    .trend {
        flex: 2.5;
        text-align: center;
        margin: 0 auto;
        padding: 8px 0;

        canvas {
            height: 65px !important;
            width: 100% !important;
        }

        @media only screen and (max-width: 500px) {
            width: 100px !important;

            canvas {
                display: inline-block !important;
                width: 100px !important;
                height: 35px !important;
            }
        }
    }

    .monthlyAverage {
        flex: 2;
        text-align: center;

        @media only screen and (max-width: 768px) {
            display: none;
        }
    }

    .lastMonth {
        flex: 1.2;
        text-align: center;

        .up{
            color: #5FD355;
        }
        .down{
            color: #E14A47				;
        }

        @media only screen and (max-width: 768px) {
            display: none;
        }
    }

    .actions {
        flex: 0.3;
        text-align: right;
    }

}

.dataRow {
    .topicName {
        cursor: pointer;
    }

    .actions {
        img {
            cursor: pointer;
        }
    }
}

@import "stylesheets/variables.scss";

.MainWindow {
	padding: 0 20px;
	@media only screen and (max-width: $max-size-1) {
		padding: 10px 5px;
	}
}
.header {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
	margin-bottom: 20px;
	@media only screen and (max-width: $max-size-2) {
		flex-direction: column;
		text-align: center;
		align-items: center;
	}
	& button {
		background: $button-color;
		border-radius: 8px;
		color: white;
		border: none;
		height: auto;
		font-family: "Poppins";
		width: 120px;
		padding: 10px;
		@media only screen and (max-width: $max-size-1) {
			left: auto;
		}
		&:hover {
			color: $fade-color;
			background: $button-color;
		}
		&:focus {
			color: $font-color;
			background: $body-color;
		}
	}
	& .activeBtn {
		background: $body-color;
		color: $font-color;
	}
	& .chartView {
		width: max-content;
		:global {
			.ant-select {
				width: max-content !important;
			}
		}
	}
}

.content {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding-bottom: 20px;
	@media only screen and (max-width: $max-size-1) {
		display: block;
	}
	&.gridLayout {
		justify-content: flex-start;
		@media only screen and (max-width: $max-size-1) {
			display: flex;
		}
		@media only screen and (max-width: 968px) {
			display: block;
		}
	}
}

.contentOneCol {
	display: block;
	& h4 {
		text-align: left !important;
	}
}
.itemMainWindow {
	border-bottom: 1px solid $line-color;
	padding: 10px;
	margin: 0 auto;
	justify-content: space-between;
	@media only screen and (max-width: $max-size-1) {
		flex-direction: column;
		padding: 5px 0;
	}
	&.customTopicBorder {
		justify-content: flex-start;
		width: 42%;
		width: calc(50% - 40px);
		width: -webkit-calc(50% - 40px);
		width: -moz-calc(50% - 40px);
		margin: 20px;
		border-bottom: none;
		border: 1px solid rgba(111, 108, 153, 0.25);
		border-radius: 3px;
		@media only screen and (max-width: $max-size-1) {
			width: 100%;
			width: calc(100% - 0px);
			width: -webkit-calc(100% - 0px);
			width: -moz-calc(100% - 0px);
			margin: 0;
			margin-bottom: 20px;
			padding: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
.activeItem {
	outline: 2px solid $button-color;
	border: 0;
}

.left {
	flex: 1;
	& h4 {
		font-family: "Poppins";
		color: $font-color;
		// width: fit-content;
		text-align: center;
		&:hover {
			color: $fade-color;
			cursor: pointer;
		}
	}
	& .topwords {
		margin: 5px 0;
		& span {
			margin: 5px;
			background: $body-color;
			padding: 10px;
			border-radius: 60px;
			display: inline-block;
			font-size: 12px;
		}
	}
}

.rightChart {
	flex: 1;
	& canvas {
		max-height: 200px !important;
	}
}
.footer {
	display: flex;
	justify-content: space-evenly;
	margin-top: 40px;

	& .paginator {
		text-align: center;
		font-family: "Poppins";
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		color: $font-color;
	}
	& .btn {
		margin: auto;
		& button {
			color: white;
			border: none;
			height: auto;
			font-family: "Poppins";
			background: $button-color;
			&:hover {
				background: $button-color;
				color: $fade-color;
			}
			&:focus {
				background: $button-color;
			}
		}
	}
}

.noTrends {
	font-family: "Poppins";
	color: $font-color;
	font-size: 25px;
	font-weight: 400;
	text-align: center;
	width: 100%;
	padding: 60px 0;
}

.trendAIView {
	display: flex;
	width: 100%;
}

.selectTopicLabel {
	width: fit-content;
	background: $fade-color;
	border-radius: 10px;
	padding: 10px 20px;
	margin: 10px auto;
	& span {
		font-family: "Poppins";
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		color: white;
	}
}

.wrapper {
	background-color: $border-color;
	max-width: 600px;
	border-radius: 30px;
	margin: 30px auto;
	padding: 30px 40px;
	.btn {
		margin-top: 0;
		padding: 30px;
	}
}

.message {
	display: flex;
	justify-content: space-evenly;
	padding-bottom: 30px;
	&_text {
		font-size: 16px;
		color: $font-color;
		margin-bottom: auto;
		margin-top: auto;
		font-family: "Poppins";
	}
	&_img {
		width: 60px;
		height: auto;
	}
	&_sub {
		padding: 0 40px;
		text-align: center;
	}
	@media screen and (max-width: $max-size-2) {
		flex-direction: column;

		&_text {
			margin: 0;
			margin-top: 20px;
		}

		&_sub {
			padding: 0;
			text-align: left;
		}
	}

	@media screen and (max-width: $max-size-3) {
		&_sub {
			text-align: center;
		}
	}
}

.status {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px 10px 15px 10px;
	& h4 {
		display: inline-block;
		font-size: 16px;
		font-weight: 600;
		margin: 0;
		text-align: left;
		margin-left: 10px;
	}

	& img {
		width: 20px;
		cursor: pointer;
	}
	.moreOptionIcon {
		position: relative;
		.moreOptionCustomTopics {
			background-color: $box-color;
			top: 100%;
			position: absolute;
			right: 0;
			padding: 5px 16px;
			box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
			z-index: 999;
			border-radius: 6px;
			span {
				color: $font-color;
				text-align: left;
				display: block;
				margin: 5px 0;
				cursor: pointer;
			}
		}
	}
}

.customHeader {
	display: flex;
	height: max-content;
}
.closeIcon {
	position: absolute;
	right: 108px;
	top: 50%;
	transform: translateY(-50%);
	svg {
		font-size: 20px;
	}
}

.cs {
	padding-left: 20px;
	padding-right: 20px;
}

.noTopics {
	color: $font-color;
	.heading {
		display: flex;
		align-items: center;
		font-size: 22px;
		justify-content: center;
		img {
			display: inline-block;
			margin-left: 8px;
		}
		@media only screen and (max-width: 500px) {
			margin-top: 40px;
			font-size: 18px;
			img {
				width: 28px;
			}
		}
	}
	.secondRow {
		border: 2px solid rgba(111, 108, 153, 0.8);
		border-radius: 6px;
		padding: 12px;
		width: 800px;
		margin: 0 auto;
		@media only screen and (max-width: 935px) {
			width: 100%;
		}
		img {
			display: inline-block;
			margin-right: 10px;
		}
	}
	.imgBlock {
		margin-top: 30px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		div {
			width: 312px;
			height: 248px;
		}
		img {
			width: 100%;
			height: 100%;
		}
		@media only screen and (max-width: 500px) {
			div {
				width: 100%;
				height: auto;
				margin-bottom: 20px;
			}
		}
	}
	.link {
		margin: 20px auto 0 auto;
		font-size: 24px;
		width: max-content;
		text-decoration: underline;
		cursor: pointer;
		@media only screen and (max-width: 500px) {
			font-size: 18px;
		}
	}
}
