@import "stylesheets/variables.scss";

.container {
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-top: 18.5%;
    gap: 1rem;
    img {
      pointer-events: none;
    }
    .msg {
      color: $font-color;
      font-size: 24px;
      @media only screen and (max-width: 949px) {
        font-size: 16px;
      }
    }
    .HomeBtn {
      width: 200px;
      height: 40px;
      font-size: 18px;
      font-weight: 600;
      color: white;
      line-height: 40px;
      border-radius: 10px;
      cursor: pointer;
      background-color: $button-color;
    }
  }
}
