@import "stylesheets/variables.scss";

.modalContent {
    width: 750px;
    background-color: $box-color;
    padding: 20px 20px;
    border-radius: 6px;
    text-align: left;
    overflow: hidden;

    @media only screen and (max-width: 500px) {
        width: 300px;
    }

    display: flex;
    flex-direction: column;
    row-gap: 20px;
    .footer{
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width: 500px) {
            flex-direction: column;
        }
    
        .texts{
            display: flex;
            flex-direction: column;
            row-gap: 5px;
            .link{
                color: $font-color;
                display: flex;
                column-gap: 5px;
                text-decoration: underline;
            }
        }
        .text{
            flex: 1;
            font-size: 13px;
            text-decoration: underline;
            display: flex;
            align-items: center;
            cursor: pointer;
            column-gap: 5px;
        }
        .btns {
            display: flex;
            justify-content: flex-end;
    
            button {
                outline: none;
                border: none;
                cursor: pointer;
                display: inline-block;
    
                &:first-child {
                    background-color: transparent;
                    text-decoration: underline;
                    margin-right: 32px;
    
                    &:hover {
                        background-color: transparent !important;
                        color: $font-color !important;
                    }
                }
    
                &:last-child {
                    background-color: $button-color;
                    padding: 8px 16px;
                    font-weight: 600;
                    border-radius: 3px;
                    color: white;
                }
            }
        }
    }

    

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        margin-bottom: 5px;

        p {
            margin: 0px;
        }

        img {
            display: inline-block;
            margin-right: 8px;
        }
        a {
            color: white;
            text-decoration: underline;
            &:hover {
              color: white !important;
              text-decoration: none;
            }
          }
    }

    .rulesWrapper {
        max-height: 250px;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .nestedRulesWrapper {
            display: flex;

            .operatorSelector {
                margin-right: 20px;
                width: 65px !important;
                flex-grow: 0 !important;

                :global {
                    .ant-select-selector {
                        background-color: $second-color !important;
                        border: none !important;
                        padding: 0 5px !important;
                        border-radius: 3px !important;
                    }
                }
            }

            .nestedRule {
                flex: 1;
                padding-left: 10px;
                border-left: solid $fade-color 1px;
            }
        }
    }
    .rulesViewer{
        .rulesQuery{
            padding: 10px;
            height: 70px;
            background-color: rgba($color: #6f6c99, $alpha: 0.3);
            border-radius: 6px;
            border: solid $fade-color 1px;
        }
    }
    .wrapper{
        display: flex;
        justify-content: space-between;
        .button {
            padding: 5px;
            border-radius: 6px;
            transition: all 0.2s ease-in-out;

            &:hover {
                background: $fade-color;
                cursor: pointer;

            }
        }
        .buttonWrapper {
            display: flex;
            justify-content: space-between;
            @media only screen and (max-width: 500px) {
                flex-direction: column;
            }
            
    
            .button {
                padding: 5px;
                border-radius: 6px;
                transition: all 0.2s ease-in-out;
                display: flex;
                column-gap: 5px;
    
                &:hover {
                    background: $fade-color;
                    cursor: pointer;
    
                }
            }
        }
    }
   

}

.groupedWhereFilter {
    display: grid;
    grid-template-columns: 70px 1fr;
    grid-template-rows: 1fr;
    align-items: flex-start;
    column-gap: 15px;

    .wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        border-left: solid $fade-color 1px;
        padding-left: 10px;
    }

    .buttonWrapper {
        flex: 1;
        display: flex;
        justify-content: space-between;

        .button {
            padding: 5px;
            border-radius: 6px;
            transition: all 0.2s ease-in-out;

            &:hover {
                background: $fade-color;
                cursor: pointer;

            }
        }
    }
    .item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .operatorSelector {
            margin-right: 15px;
            width: 65px !important;
            flex-grow: 0 !important;

            :global {
                .ant-select-selector {
                    background-color: $second-color !important;
                    border: none !important;
                    padding: 0 5px !important;
                    border-radius: 3px !important;
                }
            }
        }
    }



    :global {
        .ant-select {
            margin-top: 5px !important;

            .ant-select-selector {
                background-color: transparent !important;
                border: none !important;
                border-radius: 0 !important;
                padding: 0 !important;
                border-bottom: 1px solid rgba(111, 108, 153, 0.8) !important;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .ant-select-selection-item {
                    color: $font-color !important;
                }

                .ant-select-selection-placeholder {
                    text-align: left !important;
                    color: $font-color !important;
                }

                .ant-select-selection-search {
                    left: 0;
                    color: $font-color !important;
                }
            }

            .ant-select-arrow {
                color: $font-color !important;

                .anticon-search {
                    svg {
                        font-size: 15px;
                    }
                }
            }

            .ant-select-selection-overflow-item {
                .ant-select-selection-item {
                    color: $font-color;
                    background-color: $second-color !important;
                    border: 1px solid $second-color !important;

                    .ant-select-selection-item-remove {
                        color: $font-color;
                        text-align: right;
                        vertical-align: 0em;

                        &:hover {
                            color: $font-color !important;
                        }
                    }
                }
            }
        }
    }


    .field {
        span {
            &:first-child {
                display: block;
                text-align: left;
                font-size: 13px;
            }
        }

        input {
            border: none;
            outline: none;
            display: block;
            background-color: transparent;
            border: 1px solid rgba(111, 108, 153, 0.8);
            width: 100%;
            padding: 6px 8px;
            border-radius: 3px;
            margin-top: 5px;
            color: $font-color;
        }

        textarea {
            display: block;
            margin-top: 5px;
            width: 100%;
            resize: none;
            border: 1px solid rgba(111, 108, 153, 0.8);
            background-color: $second-color;
            border-radius: 3px;
            padding: 8px;
            outline: none;

            &::-webkit-scrollbar {
                width: 6px;
                height: 10px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $fade-color;
                border-radius: 2px !important;
            }

            &::-webkit-scrollbar-track {
                border-radius: 2px !important;
                background-color: transparent;
            }
        }
    }
}

.whereFilterWrapper {
    display: grid;
    grid-template-columns: 70px 1fr 1fr 30px;
    grid-template-rows: 1fr;
    align-items: center;
    column-gap: 15px;

    @media only screen and (max-width: 500px) {
        display: grid;
        grid-template-columns: 70px 200px 1fr 30px;
    }



    .buttonWrapper {
        display: flex;
        @media only screen and (max-width: 500px) {
            flex-direction: column;
        }

        .button {
            padding: 5px;
            border-radius: 6px;
            transition: all 0.2s ease-in-out;

            &:hover {
                background: $fade-color;
                cursor: pointer;

            }
        }
    }
    .item {
        .operatorSelector {
            margin-right: 15px;
            width: 65px !important;
            flex-grow: 0 !important;

            :global {
                .ant-select-selector {
                    background-color: $second-color !important;
                    border: none !important;
                    padding: 0 5px !important;
                    border-radius: 3px !important;
                }
            }
        }
    }
    .delete{
        cursor: pointer;
    }



    :global {
        .ant-select {
            margin-top: 5px !important;

            .ant-select-selector {
                background-color: transparent !important;
                border: none !important;
                border-radius: 0 !important;
                padding: 0 !important;
                border-bottom: 1px solid rgba(111, 108, 153, 0.8) !important;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .ant-select-selection-item {
                    color: $font-color !important;
                }

                .ant-select-selection-placeholder {
                    text-align: left !important;
                    color: $font-color !important;
                }

                .ant-select-selection-search {
                    left: 0;
                    color: $font-color !important;
                }
            }

            .ant-select-arrow {
                color: $font-color !important;

                .anticon-search {
                    svg {
                        font-size: 15px;
                    }
                }
            }

            .ant-select-selection-overflow-item {
                .ant-select-selection-item {
                    color: $font-color;
                    background-color: $second-color !important;
                    border: 1px solid $second-color !important;

                    .ant-select-selection-item-remove {
                        color: $font-color;
                        text-align: right;
                        vertical-align: 0em;

                        &:hover {
                            color: $font-color !important;
                        }
                    }
                }
            }
        }
    }


    .field {
        span {
            &:first-child {
                display: block;
                text-align: left;
                font-size: 13px;
            }
        }

        input {
            border: none;
            outline: none;
            display: block;
            background-color: transparent;
            border: 1px solid rgba(111, 108, 153, 0.8);
            width: 100%;
            padding: 6px 8px;
            border-radius: 3px;
            margin-top: 5px;
            color: $font-color;
        }

        textarea {
            display: block;
            margin-top: 5px;
            width: 100%;
            resize: none;
            border: 1px solid rgba(111, 108, 153, 0.8);
            background-color: $second-color;
            border-radius: 3px;
            padding: 8px;
            outline: none;

            &::-webkit-scrollbar {
                width: 6px;
                height: 10px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $fade-color;
                border-radius: 2px !important;
            }

            &::-webkit-scrollbar-track {
                border-radius: 2px !important;
                background-color: transparent;
            }
        }
    }
}