@import "stylesheets/variables.scss";

.customMenu {
  margin: 20px !important;
  padding: 5px !important;
  :global {
    .ant-menu-title-content {
      display: flex !important;
      column-gap: 10px !important;
    }
  }

  .customMenuItem {
    display: flex !important;
    column-gap: 10px !important;
    &:hover {
      background-color: $second-color !important;
      color: white !important;
    }
  }
}
.listing {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  .listingHeader {
    display: grid;
    grid-template-columns: 350px 1fr;
    padding: 0px 20px;

    .item {
      text-align: left;
      display: flex;
      column-gap: 10px;
    }
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
  .paginationWrapper {
    padding: 30px 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: 380px) {
      justify-content: center;
    }

    .innerWrapper {
      display: flex;
      align-items: center;
      margin-right: 20px;

      @media only screen and (max-width: 600px) {
        margin-right: 0;
      }
      @media only screen and (max-width: 380px) {
        flex-direction: column;
        justify-content: center;
      }

      .paginationBox {
        button {
          background-color: transparent !important;
          color: $font-color !important;
          border: 0px !important;
          @media only screen and (max-width: 600px) {
            padding: 4px 15px 4px 0 !important;
          }
        }
        .pagination {
          font-size: 16px;
          display: inline-block;
          input {
            display: inline-block;
            margin-right: 10px;
            border: 1px solid $font-color;
            border-radius: 4px;
            outline: none;
            width: 70px;
            height: 30px;
            padding: 0 8px;
            background-color: transparent;
            @media only screen and (max-width: 600px) {
              width: 60px;
            }
          }
          @media only screen and (max-width: 600px) {
            padding-right: 15px;
            font-size: 14px;
          }
        }
      }
      & .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: transparent !important;
      }
    }
    .reviewsTotal {
      @media only screen and (max-width: 600px) {
        display: none;
      }
    }
  }
}

.collaps {
  :global {
    .ant-collapse {
      display: flex !important;
      background-color: transparent !important;
      border: none;
    }
    .ant-collapse-header {
      flex: 1 !important;
      border-radius: 2px !important;
      padding: 0px !important;
      padding-left: 20px !important;
      border-bottom: none !important;
      display: flex !important;
      align-items: center !important;
    }
    .ant-tree-switcher,
    .ant-tree-switcher_close {
      display: flex !important;
      align-items: center !important;
    }
    .ant-collapse-expand-icon {
      display: flex;
    }
    .ant-collapse-item {
      width: 100% !important;
      flex: 1 !important;
      border-bottom: 1px solid rgba(111, 108, 153, 0.4) !important;
      display: flex !important;
      flex-direction: column !important;
    }
    .ant-collapse-content {
      border-top: 1px solid rgba(111, 108, 153, 0.4) !important;
    }
    .ant-collapse-content-box {
      padding: 15px 0px !important;
      background-color: $second-color;
      border-top: none !important;
    }
  }
}
.treeWrapper {
  padding-left: 30px;
  @media only screen and (max-width: 600px) {
    padding-left: 10px;
  }
  :global {
    .ant-tree {
      padding-left: 10px !important;
      background-color: $second-color !important;
      color: $font-color !important;
    }
    .ant-tree-treenode:not(:last-child) {
      border-bottom: 1px solid rgba(111, 108, 153, 0.4) !important;
    }
    .ant-tree-list-holder-inner {
      display: grid !important;
    }
    .ant-tree-node-content-wrapper {
      flex: 1;
    }
  }
}
.treeLeaf {
  display: flex;
  padding-right: 18px;
  align-items: center;
  .topicCheckBox {
    position: relative;
    color: black;
    appearance: none;
    outline: 0;
    border: 2px solid #636366;
    height: 18px !important;
    min-width: 18px !important;
    width: 18px !important;
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 2px;
    background-color: transparent;
    padding: inherit;
    cursor: pointer;

    &:before {
      position: absolute;
      content: "";
      display: block;
      top: 0px;
      left: 4px;
      width: 6px;
      height: 12px;
      border-style: solid;
      border-color: white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      opacity: 0;
      cursor: pointer;
    }
    &:checked {
      color: white;
      border-color: $button-color;
      background: $button-color;
      &::before {
        opacity: 1;
      }
    }
  }
  .title {
    flex: 1;
    display: flex;
    align-items: center;
    column-gap: 5px;
    padding: 10px;
    color: $font-color;
    text-align: left;
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
    .model {
      max-width: 8rem;
      min-width: 8rem;
      font-size: 10px;
      background-color: $fade-color;
      border-radius: 6px;
      margin: 5px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      display: flex;
      @media only screen and (max-width: 949px) {
        display: none;
      }
      .label {
        padding: 0;
        text-align: center;
        color: white;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .dashCount {
      flex: 1;
      display: flex;
      column-gap: 5px;
      font-weight: 500;
    }
    .details {
      display: flex;
      justify-content: center;
      color: $font-color;
      .button {
        display: flex;
        column-gap: 5px;
        background-color: $primary-color;
        padding: 5px 10px;
        border-radius: 5px;
        color: white;
      }
    }
    .more {
      display: flex;
      justify-content: flex-start;
    }
  }
}

.surverylistItem {
  flex: 1;
  background-color: $second-color;
  padding: 20px;
  padding-left: 0px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  border-radius: 6px;
  @media only screen and (max-width: 600px) {
    grid-template-columns: 30px 1fr 30px;
  }
  .topicCheckBox {
    position: relative;
    color: black;
    appearance: none;
    outline: 0;
    border: 2px solid #636366;
    height: 18px !important;
    min-width: 18px !important;
    width: 18px !important;
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 2px;
    background-color: transparent;
    padding: inherit;
    cursor: pointer;

    &:before {
      position: absolute;
      content: "";
      display: block;
      top: 0px;
      left: 4px;
      width: 6px;
      height: 12px;
      border-style: solid;
      border-color: white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      opacity: 0;
      cursor: pointer;
    }
    &:checked {
      color: white;
      border-color: $button-color;
      background: $button-color;
      &::before {
        opacity: 1;
      }
    }
  }

  .title {
    flex: 1;
    color: $font-color;
    text-align: left;
    font-size: 14px;
    margin: 0px;
  }
  .surveyContent {
    .model {
      max-width: 12rem;
      line-height: 1.5rem;
      padding: 3px 1rem;
      font-size: 12px;
      background-color: $fade-color;
      border-radius: 6px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @media only screen and (max-width: 949px) {
        display: none;
      }
      .label {
        padding: 0;
        text-align: center;
        text-transform: capitalize;
        color: white;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    display: flex;
    justify-content: flex-end;
    color: $font-color;
    gap: 0.625rem;
    .dashCount {
      flex: 1;
      display: flex;
      column-gap: 5px;
      font-weight: 500;
    }
    .moreOptionPopup {
      position: absolute;
      right: 20px;
      width: max-content;
      top: 0;
      background-color: $box-color;
      z-index: 9;
      padding: 0px 12px;
      border-radius: 6px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
      span {
        display: flex;
        align-items: center;
        margin: 15px 0;
        font-size: 13px;
        cursor: pointer;
        img {
          display: inline-block;
          margin-right: 5px;
          width: 18px;
        }
      }
    }
    .date {
      text-align: left;
      flex: 1;
      @media only screen and (max-width: 600px) {
        display: none;
      }
    }
    .surveyActions {
      @media only screen and (max-width: 600px) {
        display: none;
      }
      flex: 1;
      display: flex;
      .surveyDetails {
        background-color: $primary-color;
        display: flex;
        justify-content: flex-start;
        column-gap: 5px;
        color: white;
        padding: 2px 10px;
        border-radius: 5px;
      }
    }
    .more {
      cursor: pointer;
      @media only screen and (max-width: 600px) {
        display: none;
      }
    }
    :global {
      .ant-menu-title-content {
        display: flex !important;
        column-gap: 10px !important;
      }
      .ant-menu-item {
        background-color: red !important;
        &:hover {
          background-color: $active-color !important;
          color: $font-color !important;
        }
      }
    }
    .customMenu {
      margin: 20px !important;
      padding: 5px !important;
      :global {
        .ant-menu-title-content {
          display: flex !important;
          column-gap: 10px !important;
        }
        .ant-menu-item {
          &:hover {
            background-color: $active-color !important;
            color: $font-color !important;
          }
        }
      }

      .customMenuItem {
        display: flex !important;
        column-gap: 10px !important;
        background-color: red !important;
      }
    }
  }
}
.surveryInfoModalPopup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  .dashboardInfoModalContent {
    background-color: $box-color;
    min-width: 600px;
    max-width: 600px;
    padding: 22px 32px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    @media only screen and (max-width: 850px) {
      max-width: 600px;
      min-width: 600px;
      padding: 20px;
    }
    @media only screen and (max-width: 600px) {
      max-width: 300px;
      min-width: 300px;
      padding: 10px 15px;
    }
    .header {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      column-gap: 10px;
      .close {
        cursor: pointer;
      }
      .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 0px;
        .title {
          margin: 0px;
          font-size: 18px;
          font-weight: 500;
        }
        .description {
          margin: 0px;
          color: $fade-color;
        }
      }
    }
    .popupContent {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      .item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .title {
          display: flex;
          column-gap: 5px;
        }
        .description {
          margin-left: 30px;
          color: $fade-color;
        }
        .dashItems {
          margin-top: 10px;
          display: flex;
          column-gap: 5px;
          .dash {
            color: white;
            background-color: $primary-color;
            padding: 5px 10px;
            border-radius: 6px;
          }
        }
      }
    }
  }
}
