@import "stylesheets/variables.scss";
.listItem{
    display: grid;
    grid-template-columns: 350px 1fr 0.3fr;
    padding: 20px 20px;
    border-radius: 5px;
    background-color: $second-color;

    .item{
        text-align: left;
        display: flex;
        column-gap: 10px;
    }
    .more{
        display: flex;
        justify-content: flex-end;
    }
    @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr 1fr 0.3fr;
    }
}