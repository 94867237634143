// CUSTOM SUMMARY LIST
@import "stylesheets/variables.scss";

.sort-dropdown {
  width: 185px;
  @media only screen and (max-width: 768px) {
    width: 140px;
  }
}
.sort-dropdown .ant-select-selector {
  border: 0 !important;
  background-color: $body-color !important;
  color: $font-color;
  width: 185px;
  text-align: left;
  padding: 0 17px !important;
  height: 40px !important;
}
.sort-dropdown .ant-select-arrow {
  color: $font-color !important;
}
.ant-select-dropdown {
  background-color: $border-color !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: none !important;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: transparent !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: $primary-color !important;
}
.ant-select-item {
  color: #fff !important;
}
.ant-select-selection-item {
  display: flex;
  align-items: center;
}

.moreOptionIcon {
  position: relative;
}
.moreOptionBox {
  border-radius: 6px;
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  background-color: $box-color;
  display: flex;
  flex-direction: column;
  padding: 6px 0;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
  width: max-content;
}
.moreOptionBox span {
  cursor: pointer;
  padding: 2px 16px !important;
  font-size: 13px;
  text-align: left;
  color: $font-color;
  display: flex;
  column-gap: 5px;
}

.review-page-number-selector {
  @media only screen and (max-width: 600px) {
    font-size: 13px !important;
  }
}
.review-page-number-selector.ant-select {
  color: $font-color !important;
  margin-left: 10px !important;
  display: inline-block !important;
  width: 128px !important;
  @media only screen and (max-width: 600px) {
    width: 110px !important;
  }
  @media only screen and (max-width: 380px) {
    margin: 0 !important;
    margin-top: 10px !important;
  }
}
.review-page-number-selector .ant-select-selector {
  border: 1px solid $font-color !important;
  background-color: transparent !important;
}
.review-page-number-selector.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 4px !important;
}
.review-page-number-selector .ant-select-arrow {
  color: $font-color !important;
}
.review-page-number-selector:not(.ant-select-disabled):hover
  .ant-select-selector {
  border-color: $font-color !important;
}
.review-page-number-selector.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: $font-color !important;
}
