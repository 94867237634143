@import "stylesheets/variables.scss";

.svg-inline--fa {
  margin-right: 0px !important;
  color: #fff !important;
}

.ant-menu-overflow {
  align-items: center;
}

.ant-menu-dark.ant-menu-horizontal {
  padding: 5px !important;
}

.ant-menu-overflow-item {
  padding: 0 12px !important;

  & .ant-menu-title-content {
    margin: 0 5px !important;
    color: $font-color !important;
    font-size: 16px;
    padding: 0px !important;
  }
}
.sub-menu-item {
  .underline-item {
    span {
      text-decoration: underline;
      font-size: 14px;
      font-family: "Poppins";
      margin-left: 0 !important;
      color: $font-color !important;
    }
  }
}
// .ant-menu-vertical {
//   .ant-menu-item {
//     margin: 0 !important;
//     font-family: "Poppins";
//     display: flex;
//     align-items: center;

//     span {
//       margin-left: 3px !important;
//       color: $font-color;
//     }
//   }
// }

.avatar-box {
  margin-right: 27px !important;
  .ant-menu-title-content {
    width: 24px !important;
    height: 24px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("/assets/avatar.png");
    background-position: center;
    background-size: contain;
    border-radius: 50%;
    margin-left: 0 !important;

    span {
      width: 24px !important;
      height: 24px !important;
      color: white !important;
    }
  }
}

.avatar-icon {
  position: relative !important;

  & .user-name-letter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.avatar-submenu {
  .ant-menu-vertical {
    .ant-menu-item {
      margin: 0 !important;
    }
  }
  width: max-content !important;
  .avatar-item {
    color: $font-color !important;
    width: max-content !important;
  }
}
@media only screen and (max-width: 650px) {
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item {
    padding: 0 15px 0 10px !important;
  }
}

.topbar-notification-icon {
  margin-right: -5px;
  padding: 0 12px;
  position: relative;

  img {
    cursor: pointer;
  }
}

.nav-logo-container {
  @media only screen and (max-width: 1100px) {
    padding: 0px 0px 0px 50px !important;
  }

  @media screen and (max-width: 650px) {
    padding: 18px 0px 0 8px !important;
  }

  @media screen and (max-width: 500px) {
    width: 220px !important;
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    width: 550px;
    height: 150px;
    background-color: $box-color;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .texts {
        display: flex;
        flex-direction: column;
        text-align: left;
        .title {
          font-weight: 600;
          color: $font-color;
        }
        .description {
          font-size: 12px;
          color: $fade-color;
        }
      }

      .close {
        align-self: flex-start;

        &:hover {
          cursor: pointer;
        }
      }
    }
    .inputWrapper {
      display: flex;
      align-items: center;
      column-gap: 10px;
      input {
        border: none;
        outline: none;
        display: block;
        background-color: transparent;
        border: 1px solid rgba(111, 108, 153, 0.8);
        width: 100%;
        padding: 6px 8px;
        border-radius: 3px;
        color: $font-color;
      }
      .button {
        background-color: $active-color !important;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        border: solid $fade-color 1px;
        color: $font-color;

        &:hover {
          background-color: $fade-color !important;
        }
      }
    }
  }
}
