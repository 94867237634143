@import "stylesheets/variables.scss";
@font-face {
  font-family: AvantGardeDemi;
  src: url("/fonts/ITCAvantGardeStd-Demi.ttf");
}

:root {
  --scaling-factor: 1.075;
}

html {
  background: $body-color;
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  font-family: "Poppins";
}
.navbar {
  padding: 0.5rem 5% !important;
}
body {
  background-color: $body-color !important;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll !important;
}
a:not([href]) {
  color: #007bff !important;
}
button:focus {
  outline: none !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: $active-color !important;
}
.ant-picker-footer .ant-picker-today-btn {
  color: $fade-color !important;
}
.ant-tag .anticon-close {
  position: relative !important;
  bottom: 3px !important;
}
.styles_header__3PFGX {
  position: relative !important;
  background-color: #100d31;
  height: 100px !important;
}
.styles_LogoImg__2wRK6 {
  height: 70px !important;
}
.ant-spin-dot-item {
  background-color: $active-color !important;
}
.nav-logo {
  // height: 48px;
  float: left;
}
.ant-select-item-option-disabled {
  background-color: rgba(217, 223, 227, 0.804) !important;
  opacity: 0.7 !important;
}
.ant-dropdown-menu-item-disabled {
  background-color: rgba(217, 223, 227, 0.804) !important;
  opacity: 0.7 !important;
}
.nav-logo-container {
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
}

@media only screen and (max-width: 500px) {
  .nav-logo-container {
    width: 160px;
  }
}

.nav-logo-container h5 {
  margin-bottom: 0;
  font-weight: 700;
  color: white;
  font-size: 25px;
  padding-top: 1px;
}
.nav-logo-container img {
  /* height: 30px; */
  // margin-right: 5px;
  width: 140px;
}
.ant-notification-notice-description {
  word-break: break-all;
}

.anticon.ant-notification-notice-icon-info {
  color: $primary-color !important;
}

span.ant-notification-notice-close-x {
  color: $font-color !important;
}

.BoardInfoPopover .ant-popover-inner-content {
  padding: 0 !important;
}
.ant-dropdown {
  z-index: 50 !important;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}
// .ant-dropdown-menu-item-group-title {
//   /* background: #001529 !important; */
// }
[aria-checked="false"] {
  background-color: grey !important;
}
.ant-modal-content,
.ant-modal-header,
.ant-notification-hook-holder,
.ant-notification-notice,
.ant-notification-notice-description {
  background: $box-color !important;
  color: $font-color !important;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}
.ant-modal-title,
.ant-modal-close-x .anticon,
.ant-notification-close-x .anticon,
.ant-modal-content h5,
.ant-notification-notice-message,
.ant-notification-notice-description {
  color: $font-color !important;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}
.ant-notification-notice,
.ant-notification-notice-message,
.ant-notification-close-x,
.ant-notification-notice-description {
  color: $font-color !important;
  :hover {
    color: $font-color !important;
  }
}
textarea {
  color: $font-color !important;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  :placeholder-shown {
    color: $font-color !important;
    opacity: 0.5 !important;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif !important;
  }
}
.ant-menu-dark:not(.top-navigation-bar) .ant-menu-sub,
.ant-menu.ant-menu-dark:not(.top_navigation-bar) .ant-menu-sub {
  background: $box-color !important;
}
.ant-menu-item-selected {
  border-radius: 40px 0 0 40px;
}
.ant-menu.ant-menu-dark:not(.top-navigation-bar),
.ant-dropdown-menu-dark {
  background: $box-color !important;
  color: $font-color !important;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}
// .ant-menu-sub.ant-menu-inline > .ant-menu-item {
//   padding-left: 16px !important;
// }
.ant-menu-dark.ant-menu-horizontal {
  padding: 5px 40px !important;
}
.ant-table-tbody > tr > td {
  border-color: rgba(240, 240, 240, 0.2) !important;
}
.ant-checkbox .ant-checkbox-inner {
  border-radius: 50%;
}
.ant-dropdown-menu-title-content {
  color: $font-color;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $button-color !important;
  border-color: $button-color !important;
}
.slick-list
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: #e0b5ea !important;
  color: #891199 !important;
}
.slick-list
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #e0b5ea !important;
}
.ant-list-split .ant-list-item {
  border-color: rgba(240, 240, 240, 0.2) !important;
}
.ant-carousel .slick-list {
  min-height: 800px !important;
}
.ant-carousel .slick-dots {
  height: 20px;
}
.ant-carousel .slick-dots li.slick-active button {
  background: #891199 !important;
  box-sizing: border-box;
  height: 5px;
  width: 45px !important;
  border: 1px solid #891199;
}
.ant-carousel .slick-dots li button {
  background: #e0e0e0 !important;
  box-sizing: border-box;
  height: 5px !important;
  width: 40px !important;
  pointer-events: none;
  /* border: 1px solid #E0E0E0 !important; */
}
.ant-carousel .slick-dots li {
  margin: 0;
  width: 40px !important;
}

.ant-carousel .slick-dots li {
  margin: 0 !important;
}

.ant-form-item {
  display: block !important;
  text-align: left;
  margin-bottom: 12px !important;
}
.ant-form-item-label > label {
  font-family: "AvantGardeDemi";
  margin: 0 !important;
}
.ant-form-item-label > label::after {
  content: "" !important;
}
.ant-form-item-explain.ant-form-item-explain-error {
  margin-bottom: 5px;
}
.slick-list .ant-radio-button-wrapper {
  width: 90% !important;
  margin: 10px !important;
  padding: 0 10px !important;
}

@media only screen and (max-width: 650px) {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 5px !important;
  }

  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item {
    padding: 0 10px !important;
  }

  .ant-menu-dark.ant-menu-horizontal {
    padding: 5px 0 !important;
  }
  .nav-logo-container {
    padding: 18px 0px 0 8px;
    height: 38px;
  }
  .nav-logo-container img {
    width: 100px;
  }
  /* .ant-menu-item, .ant-menu-submenu-title{
    padding: 0 5px !important;
  } */
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
    padding: 0 10px !important;
  }
  .ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0 !important;
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #891199 !important;
  color: #e9e9e9 !important;
}

button.primary-background {
  background-color: $primary-color;
}

span.ant-progress-text {
  color: #fff;
}

@media only screen and (max-width: $max-size-2) {
  // #popup, #popup_selector {
  //   display: none;
  // }
}
.ant-btn.ant-btn-default:not(.red):hover {
  color: #fff !important;
  background-color: $button-color !important;
  border-color: $button-color !important;
}

.ant-select-selection-search,
.ant-select-selection-item {
  color: $font-color !important;
}
button.ant-btn-primary {
  color: $font-color;
  background-color: $body-color;
  border-color: $body-color;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}
div,
span,
input,
p,
a,
button {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  :placeholder-shown {
    color: $font-color !important;
    opacity: 0.5 !important;
  }
  ::placeholder {
    color: $font-color !important;
    opacity: 0.5 !important;
  }
}
input {
  color: $font-color !important;
}
button.ant-btn-primary:hover,
button.ant-btn-primary:focus {
  background-color: $button-color !important;
  border-color: $button-color !important;
  color: white !important;
}
button.ant-btn-primary:active {
  background-color: $button-color !important;
  border-color: $button-color !important;
  color: white !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: $primary-color !important;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: $primary-color !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: $border-color !important;
}
.ant-input {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  background-color: transparent !important;
  color: $font-color !important;
  &:hover {
    background-color: transparent !important;
    color: $font-color !important;
  }
  &:placeholder-shown {
    color: $font-color !important;
    opacity: 0.5 !important;
  }
  ::placeholder {
    color: $font-color !important;
    opacity: 0.5 !important;
  }
}
.ant-input-outlined {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  background-color: transparent !important;
  color: $font-color !important;
  &:hover {
    background-color: transparent !important;
    color: $font-color !important;
  }
  &:placeholder-shown {
    color: $font-color !important;
    opacity: 0.5 !important;
  }
  ::placeholder {
    color: $font-color !important;
    opacity: 0.5 !important;
  }
}
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: $body-color !important;
  border-color: $border-color !important;
  color: $font-color !important;
}

.ant-radio-button-wrapper:hover {
  color: $body-color !important;
}

a:hover {
  color: $primary-color !important;
}

.ant-modal-content .ant-btn {
  background-color: #1e1a3d;
  border-color: #1e1a3d;
  color: white;
}

.ant-modal-content button.ant-btn-primary {
  background-color: #4400aa;
  border-color: #4400aa;
  color: white;
}

.ant-modal-content button.ant-btn-primary:hover {
  background-color: white;
  color: #1e1a3d;
}
::selection {
  color: #fff;
  background: $button-color !important;
}
.ant-typography-edit {
  color: $primary-color !important;
}
.ant-select-clear {
  background: #262250 !important;
}
.ant-select-arrow {
  color: $font-color !important;
}
.ant-menu-title-content {
  height: fit-content !important;
  max-width: 170px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: wrap !important;
  :hover {
    color: $font-color !important;
  }
}
.ant-menu-item {
  text-align: left !important;
}
.ant-color-picker .ant-color-picker-inner {
  .ant-color-picker-input {
    color: $font-color !important;
    .ant-input-affix-wrapper {
      color: $font-color !important;
    }
    .ant-input {
      color: $font-color !important;
    }
  }
  .ant-select-selection-item {
    color: $font-color !important;
  }
  .ant-select-arrow {
    color: $font-color !important;
  }
}
.ant-popover-content .ant-popover-inner {
  color: $font-color !important;
}
.ant-color-picker-input-container {
  color: $font-color !important;
}
//override for theme
.light-side-menu.ant-menu-dark.ant-menu-inline {
  background-color: #e8e0f4 !important;
  .ant-menu-item {
    color: rgba(0, 0, 0, 0.9) !important;
    :hover {
      color: rgba(0, 0, 0, 0.9) !important;
      text-decoration: underline !important;
    }
  }
  .ant-menu-item-active {
    color: rgba(0, 0, 0, 0.9) !important;
  }
  .svg-inline--fa {
    color: rgba(0, 0, 0, 0.9) !important;
    :hover {
      color: rgba(0, 0, 0, 0.9) !important;
    }
  }
  .ant-menu-item-selected {
    background-color: transparent !important;
    span {
      color: #4400aa;
    }
    .svg-inline--fa {
      color: #4400aa !important;
    }
    i {
      color: #4400aa !important;
    }
  }
}

.brand-side-menu.ant-menu-dark.ant-menu-inline {
  background-color: #f4f4f4 !important;
  .ant-menu-item {
    color: #4a4d4e;
    :hover {
      color: #4a4d4e !important;
      text-decoration: underline !important;
    }
  }
  .ant-menu-item-active {
    color: #4a4d4e !important;
  }
  .svg-inline--fa {
    color: #4a4d4e !important;
    :hover {
      color: #4a4d4e !important;
    }
  }
  .ant-menu-item-selected {
    background-color: transparent !important;
    span {
      color: #990000;
    }
    .svg-inline--fa {
      color: #990000 !important;
    }
    i {
      color: #990000 !important;
    }
  }
}

.setting-light-sidebar.ant-menu-dark.ant-menu-inline {
  background-color: #e8e0f4 !important;
  .ant-menu-item {
    color: rgba(0, 0, 0, 0.9);
    :hover {
      color: rgba(0, 0, 0, 0.9) !important;
      text-decoration: underline !important;
    }
  }
  .ant-menu-item-active {
    color: rgba(0, 0, 0, 0.9) !important;
  }
  .svg-inline--fa {
    color: rgba(0, 0, 0, 0.9) !important;
    :hover {
      color: rgba(0, 0, 0, 0.9) !important;
    }
  }
  .ant-menu-item-selected {
    background-color: transparent !important;
    span {
      color: #4400aa;
    }
    .svg-inline--fa {
      color: #4400aa !important;
    }
  }
}

.setting-brand-sidebar.ant-menu-dark.ant-menu-inline {
  background-color: #f4f4f4 !important;
  .ant-menu-item {
    color: #4a4d4e !important;
    :hover {
      color: #4a4d4e !important;
      text-decoration: underline !important;
    }
  }
  .ant-menu-item-active {
    color: #4a4d4e !important;
  }
  .svg-inline--fa {
    color: #4a4d4e !important;
    :hover {
      color: #4a4d4e !important;
    }
  }
  .ant-menu-item-selected {
    background-color: transparent !important;
    span {
      color: #990000;
    }
    .svg-inline--fa {
      color: #990000 !important;
    }
  }
}

// TOP WORD HOVER OVERRIDE
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: white !important;
}

// SCROLL TO TOP
#scroll-to-top {
  position: fixed;
  right: 0;
  bottom: -48px;
  width: 48px;
  height: 48px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  background-color: $second-color;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: bottom 0.3s ease;
  cursor: pointer;
  i {
    font-size: 24px;
    color: $font-color;
  }
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 42px;
  height: 42px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 6px;
  border: 3px solid #4400aa;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #4400aa transparent transparent transparent;
}

.lds-ring.small {
  width: 25px;
  height: 25px;

  & div {
    width: 25px;
    height: 25px;
    border: 2px solid var(--color-primary);
    border-color: var(--color-primary) transparent transparent transparent;
    margin: 0;
  }
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
