.consoleLayoutRoot {
	display: flex;
	height: inherit;
	overflow: hidden;
}
.consoleLayoutRoot-sideMenu {
	flex: 1;
	text-align: left;
}
.drawer-content {
	background-color: #262250 !important;
}
.drawer-handle {
	top: 8px;
	right: -55px !important;
	box-shadow: none !important;
	background-color: #262250 !important;
}
.drawer-handle-icon {
	background: white !important;
	width: 20px;
}
.drawer.drawer-open .drawer-handle-icon {
	background: transparent !important;
}
.drawer-handle-icon::after {
	background: white;
	top: 6px;
}
.drawer-handle-icon::before {
	background: white;
	top: -6px;
}
.drawer.drawer-open .drawer-handle-icon::after {
	transform: translateY(-6px) rotate(-45deg) !important;
}
.drawer.drawer-open .drawer-handle-icon::before {
	transform: translateY(6px) rotate(45deg) !important;
}
.light .drawer-content {
	background: #e8e0f4 !important;
}
.brand .drawer-content {
	background-color: #f4f4f4 !important;
}
.light .drawer-handle {
	background-color: #4400aa !important;
}
.brand .drawer-handle {
	background-color: #e60000 !important;
}

@media only screen and (min-width: 1082px) {
	.consoleLayoutRoot-sideMenu {
		min-width: 220px;
		max-width: 220px;
	}
}

.consoleLayoutRoot-content {
	flex: 6;
	height: inherit;
	overflow: auto;
}
.svg-inline--fa {
	margin-right: 10px;
}
.consoleLayoutRoot-sideMenu .ant-menu {
	padding-top: 30px;
	height: 100%;
}

@media only screen and (max-width: 1100px) {
	.consoleLayoutRoot-sideMenu {
		flex: 0;
	}
	.consoleLayoutRoot-sideMenu .ant-menu {
		padding-top: 28px;
		height: 100%;
	}
}
.sub-menu-item .ant-menu-item-group-title {
	display: none !important;
}
.sub-menu-item .ant-menu-item-group-list .ant-menu-item {
	margin-bottom: 4px !important;
}
.sub-menu-item .ant-menu-item-group-list .ant-menu-item:last-child {
	margin-bottom: 10px !important;
}
.sub-menu-item .ant-menu-item-group-list {
	padding-left: 23px !important;
}

.sub-Menu-item .ant-menu-item {
	position: relative !important;
	overflow: visible !important;
}
.ant-menu-inline .ant-menu-item {
	overflow: visible !important;
}
.right-click-popup {
	position: absolute;
	top: 0;
	left: 65%;
	z-index: 2;
	border-radius: 6px;
	background-color: #100d31;
	color: white;
	padding: 0 15px;
	cursor: pointer;
	font-size: 12px;
}

.side-menu-item-wrapper .ant-menu-item:not(:last-child):not(.sub-menu-item .ant-menu-item) {
	margin-bottom: 15px !important;
}
