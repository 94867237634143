@import "stylesheets/variables.scss";
.container {
  min-height: calc(100vh - 56px);
  padding: 40px 0;
  background: white;
}

.carouselContent {
  background-color: white;
  height: calc(100vh - 56px);
  padding-top: 20px;
  width: 400px !important;

  @media only screen and (max-width: 600px) {
    width: 300px !important;
  }

  &_fullWidth {
    width: 100% !important;
    background-color: white;
    min-height: calc(100vh - 56px);
    padding-top: 20px;
  }
}

.title h1 {
  width: 100%;
  color: #000;
  font-family: "AvantGardeDemi";
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  margin: 10px 0;
}
.switch {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  & p {
    margin: 0 10px;
  }
}
.Dashboards {
  & p {
    display: inline;
    margin-right: 20px;
    font-weight: 600;
  }
}
.DashboardsWrapper {
  display: flex;
  justify-content: center;

  @media only screen and (max-width: $max-size-2) {
    flex-direction: column;

    .Dashboards {
      margin-bottom: 20px;
    }
  }

  div {
    margin-right: 20px;
  }
}
.btnContainer {
  // display: flex;
  justify-content: space-between;
  text-align: right;
}
.btn {
  height: 37px;
  width: 158px;
  border-radius: 30px !important;
  background-color: #891199 !important;
  color: #fff !important;
  font-family: "AvantGardeDemi" !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  padding: 10px !important;
  text-align: center !important;
  border: 0 !important;
  text-transform: uppercase !important;
  margin: 0 10px !important;
}
.carouselContent {
  .form {
    .btn {
      &:hover,
      &:active,
      &:focus {
        background-color: #891199 !important;
      }
    }
  }
}
.or {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #bdbdbd;
  line-height: 0.1em;
  margin: 10px 0 20px;
}
.or span {
  background: #fff;
  padding: 0 10px;
  height: 14px;
  width: 14px;
  color: #bdbdbd;
  font-family: "AvantGardeDemi";
  font-size: 14px;
  font-weight: 300;
  text-align: center;
}
.inputForm {
  box-sizing: border-box;
  height: 34px;
  width: 100%;
  border-radius: 4px;
  background-color: #f2f2f2;
  border: 1px solid #d7d7d7;
  padding: 10px;
  color: black !important;
  &:placeholder-shown {
    color: black !important;
  }
  ::placeholder {
    color: black !important;
  }
  &:focus {
    border: 1px solid #d7d7d7;
    outline: none;
  }
}

.gmailSignup {
  box-sizing: border-box;
  height: 40px;
  border-radius: 6px;
  background-color: #fff;
  border: 2px solid #891199;
  width: 100%;

  & img {
    width: 28px;
    float: left;
    margin-left: 30px;
  }

  & span {
    color: #4f4f4f;
    font-family: "AvantGardeDemi";
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin: 0 auto;
    position: relative;
    top: 3px;
  }
  &:hover {
    background-color: white !important;
  }
}
.recaptcha {
  margin: 20px 0;
  text-align: center;
}
.terms {
  font-size: 15px;
  margin: 12px 10px 0;
}
.plans {
  display: flex;
  margin: 20px 0;
  justify-content: center;
  padding: 20px;
  background-color: rgba(224, 181, 234, 0.16);
  @media only screen and (max-width: $max-size-2) {
    flex-direction: column;
  }

  &_selected {
    display: flex;
    margin: 20px 0;
    justify-content: center;
    padding: 20px;
    background-color: rgba(224, 181, 234, 0.16);
    @media only screen and (max-width: $max-size-2) {
      flex-direction: column;
    }
  }
}

.planCard {
  flex: 1;
  // margin: 10px auto;
  position: relative;
  padding: 20px 10px 50px;
  max-width: 370px;
  background-color: white;
  margin: 5px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: $max-size-2) {
    margin: 0 auto;
    width: 350px;
  }
  // height: 100%;
  // justify-content: space-between;

  & h1 {
    color: #333;
    font-family: "AvantGardeDemi";
    font-size: 21px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
  }
  & h2 {
    color: #4f4f4f;
    font-family: "AvantGardeDemi";
    font-size: 16px;
    font-weight: 300;
    line-height: 16px;
    text-align: center;
  }
  & .prices {
    margin: 20px 0;

    & span {
      font-family: Arial;
      color: #333;
    }
    & h3 {
      color: #891199;
      font-family: "Poppins";
      font-size: 32px;
      font-weight: 900;
      line-height: 30px;
      text-align: center;
    }
    & h4 {
      color: #ba89c6;
      font-family: "AvantGardeDemi";
      font-size: 25px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
      -webkit-text-decoration-line: line-through; /* Safari */
      text-decoration-line: line-through;
    }
  }
  & .pros {
    & .prosItem {
      display: flex;
      & p {
        color: #333;
        font-family: Arial;
        font-size: 16px;
        line-height: 22px;
        text-align: left;
      }
      & img {
        height: 20px;
        // position: relative;
        // top: 3px;
        margin: 0 10px;
      }
    }
  }
  .chooseBtn {
    text-align: right;
    padding: 0 20px;
    margin-top: 20px;
    position: absolute;
    bottom: 20px;
    right: 0;
    & button,
    a {
      height: 37px;
      // width: 158px;
      border-radius: 30px;
      background-color: #891199;
      border: none;
      color: white;
      font-family: "AvantGardeDemi";
      text-transform: uppercase;
      font-size: 15px;
      font-weight: 500;
      line-height: 20px;
      padding: 10px;
      text-align: center;
    }
  }
}
.selectionDetails {
  width: 50%;
  margin: 0 50px;
  text-align: center;
  @media only screen and (max-width: 600px) {
    width: 90%;
    margin: 40px 20px;
  }
  & h3 {
    color: #891199;
    font-family: "AvantGardeDemi";
    font-size: 25px;
    font-weight: 500;
    line-height: 27px;
    text-align: center;
  }
  & h5 {
    color: #4f4f4f;
    font-family: "AvantGardeDemi";
    font-size: 16px;
    font-weight: 300;
    line-height: 16px;
    text-align: center;
  }
}
.payment {
  // height: 348px;
  max-width: 456px;
  border-radius: 7px;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(55, 44, 70, 0.37);
  text-align: left;
  margin: 100px auto;
  padding: 40px;
  @media only screen and (max-width: 600px) {
    padding: 20px;
  }

  & h3 {
    color: #333;
    font-family: "AvantGardeDemi";
    font-size: 21px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
  }
  & p {
    color: #4f4f4f;
    font-family: "AvantGardeDemi";
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
  }
  & img {
    height: 40px;
    margin-bottom: 20px;
  }
}
.verified {
  & img {
    max-height: 200px;
    margin: 20px auto;
  }
}
.welcome {
  padding: 20px;
  border-radius: 7px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.31);
  & p {
    color: #4f4f4f;
    font-family: "AvantGardeDemi";
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    text-align: center;
    margin-bottom: 5px;
  }
  & span {
    color: #39f;
    cursor: pointer;
  }
}
